define("frontend/components/settlement-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    ownerAvrstatAttributes: Ember.computed.filterBy('ownerAttributes', 'attr.attr_name', 'Avrstat'),
    ownerHasAvrstatAttribute: Ember.computed.gt('ownerAvrstatAttributes.length', 0),
    showSettlementLink: Ember.computed.alias('ownerHasAvrstatAttribute'),
    avrstatAttributeValues: Ember.computed.filterBy('attributeValues', 'attr.attr_name', 'Avrstat'),
    avrstatAttributeValue: Ember.computed.alias('avrstatAttributeValues.firstObject'),
    isValue3: Ember.computed.equal('avrstatAttributeValue.value', '3'),
    isValue8: Ember.computed.equal('avrstatAttributeValue.value', '8'),
    isValue9: Ember.computed.equal('avrstatAttributeValue.value', '9'),
    settlementLinkEnabled: Ember.computed.or('isValue3', 'isValue8', 'isValue9'),
    fsnrAttributeValues: Ember.computed.filterBy('attributeValues', 'attr.attr_name', 'Fsnr'),
    fsnrAttributeValue: Ember.computed.alias('fsnrAttributeValues.firstObject'),
    sndnrAttributeValues: Ember.computed.filterBy('attributeValues', 'attr.attr_name', 'Sndnr'),
    sndnrAttributeValue: Ember.computed.alias('sndnrAttributeValues.firstObject'),
    fsnr: Ember.computed.alias('fsnrAttributeValue.value'),
    sndnr: Ember.computed.alias('sndnrAttributeValue.value'),
    owner: Ember.computed.alias('ownerAttributes.firstObject.owner'),
    document: Ember.computed.alias('attributeValues.firstObject.doc'),
    showExternalLink: Ember.computed.equal('user.settings.settlementLink', true),
    actions: {
      openSettlementModal: function openSettlementModal() {
        this.set('settlementModalOpen', true);
      }
    }
  });

  _exports.default = _default;
});