define("frontend/components/report-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    rev: 0,
    trpnrs: Ember.computed('user.owner.trpnrs', function () {
      var trpnrs = this.get('user.owner.trpnrs');

      if (trpnrs.length > 1) {
        trpnrs = [''].concat(trpnrs);
      }

      return trpnrs;
    }),
    assignments: Ember.computed('user.owner.assignments', function () {
      var assignments = this.get('user.owner.assignments');
      if (!assignments) return [];

      if (assignments.length > 1) {
        assignments = [''].concat(assignments);
      }

      return assignments;
    }),
    _compare: function _compare(a, b) {
      if (Ember.isArray(a)) {
        if (!Ember.isArray(b)) {
          return false;
        }

        if (a.length !== b.length) {
          return false;
        }

        return a.join() === b.join();
      }

      return a === b;
    },
    isEqualConfig: function isEqualConfig(config, comparison) {
      for (var _i = 0, _Object$entries = Object.entries(comparison); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
            k = _Object$entries$_i[0],
            v = _Object$entries$_i[1];

        if (!this._compare(config[k], v)) {
          return false;
        }
      }

      for (var _i2 = 0, _Object$entries2 = Object.entries(config); _i2 < _Object$entries2.length; _i2++) {
        var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i2], 2),
            _k = _Object$entries2$_i[0],
            _v = _Object$entries2$_i[1];

        if (!this._compare(comparison[_k], _v)) {
          return false;
        }
      }

      return true;
    },
    fields: Ember.computed('report.report_default_config', 'user.owner_id', 'user.owner.trpnrs.firstObject', 'rev', function () {
      var specification = {
        scan_date_from: {
          id: this.report.report_name + '_scan_date_from',
          name: 'scan_date_from',
          label: 'Skannerdatum från',
          type: 'date'
        },
        scan_date_to: {
          id: this.report.report_name + '_scan_date_to',
          name: 'scan_date_to',
          label: 'Skannerdatum till',
          type: 'date'
        },
        avg_date_from: {
          id: this.report.report_name + 'avg_date_from',
          name: 'avg_date_from',
          label: 'Avgångsdatum från',
          type: 'date'
        },
        avg_date_to: {
          id: this.report.report_name + 'avg_date_to',
          name: 'avg_date_to',
          label: 'Avgångsdatum till',
          type: 'date'
        },
        trpnrs: {
          id: this.report.report_name + '_trpnrs',
          name: 'trpnrs',
          label: 'Transportörsnummer',
          type: 'trpnr',
          value: this.get('user.owner.trpnrs.length') === 1 ? this.get('user.owner.trpnrs.firstObject') : undefined
        },
        uppdrag: {
          id: this.report.report_name + '_uppdrag',
          name: 'uppdrag',
          label: 'Uppdrag',
          type: 'uppdrag',
          value: '' // Alltid tomt initialvärde

        },
        owner_id: {
          name: 'owner_id',
          type: 'hidden',
          value: this.get('user.owner_id')
        },
        date_from: {
          id: this.report.report_name + '_date_from',
          name: 'date_from',
          label: 'Datum från',
          type: 'date',
          required: true
        },
        date_to: {
          id: this.report.report_name + '_date_to',
          name: 'date_to',
          label: 'Datum till',
          type: 'date',
          required: true
        },
        count_from: {
          id: this.report.report_name + '_count_from',
          name: 'count_from',
          label: 'Antal rättelser från',
          type: 'number',
          required: true,
          value: 0
        },
        count_to: {
          id: this.report.report_name + '_count_to',
          name: 'count_to',
          label: 'Antal rättelser till',
          type: 'number',
          required: true,
          value: 99
        },
        bump_count: {
          id: this.report.report_name + '_bump_count',
          name: 'bump_count',
          label: 'Uppdatera antal rättelser',
          type: 'checkbox',
          required: false,
          value: false
        },
        fordon: {
          id: this.report.report_name + '_fordon',
          name: 'fordon',
          label: 'Fordon',
          type: 'text',
          required: false,
          value: ''
        }
      };
      var fields = [];
      var fieldNames = Object.keys(this.report.report_default_config);
      fieldNames.forEach(function (name) {
        if (specification[name]) {
          fields.push(specification[name]);
        }
      });
      return fields;
    }),
    isBeingCreated: Ember.computed.not('isFinished'),
    isFinished: Ember.computed.alias('reportQueue.report_time_finished'),
    isLoading: Ember.computed('isSaving', 'reportQueue', 'isFinished', function () {
      return this.get('isSaving') || this.get('reportQueue') && this.get('isBeingCreated');
    }),
    _reload: function _reload() {
      var _this = this;

      if (!this.get('isFinished') && this.get('reportQueue')) {
        this.get('reportQueue').reload().then(function () {
          Ember.run.later(_this, _this._reload, 1000);
        });
      }
    },
    actions: {
      createReport: function createReport() {
        var _this2 = this;

        var config = this.get('report.report_default_config');
        this.get('fields').forEach(function (field) {
          config[field.name] = field.value;
        });
        var reportQueue = this.get('store').createRecord('report-queue', {
          report: this.report,
          report_config: config
        });
        this.set('isSaving', true);
        reportQueue.save().then(function () {
          M.toast({
            html: 'Rapporten har lagts till i kön',
            classes: 'blue darken-1 rounded'
          });

          _this2.set('reportQueue', reportQueue);

          _this2.set('isSaving', false);

          Ember.run.later(_this2, _this2._reload, 1000);
        }, function () {
          _this2.set('isSaving', false);

          M.toast({
            html: 'Det gick inte att skapa rapporten',
            classes: 'red darken-1 rounded'
          });
        });
      },
      cancel: function cancel() {
        this.set('reportQueue');
        this.$('.modal-report').modal('close'); // Increment to reset all data in the form

        this.incrementProperty('rev');
      },
      reset: function reset() {
        this.set('reportQueue');
      },
      messageReport: function messageReport() {
        var _this3 = this;

        this.set('reportQueue.message', true); // Increment to reset all data in the form

        this.incrementProperty('rev');
        this.get('reportQueue').save().then(function () {
          M.toast({
            html: 'Ett meddelande kommer att skickas till dig när rapporten är klar!',
            classes: 'blue darken-1 rounded'
          });

          _this3.send('cancel');
        });
      }
    }
  });

  _exports.default = _default;
});