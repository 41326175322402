define("frontend/routes/reset-response", ["exports", "frontend/routes/route", "frontend/utils/is-authenticated", "jquery", "frontend/config/environment"], function (_exports, _route, _isAuthenticated, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    redirect: function redirect() {
      var _this = this;

      (0, _isAuthenticated.default)().then(function () {
        _this.transitionTo('documents');
      });
    },
    model: function model(data) {
      return new Promise(function (resolve, reject) {
        var host = _environment.default.APP.apiHost;
        var value = {
          resetValidation: data
        };

        _jquery.default.ajax(host + '/api/reset/validate/', {
          type: 'POST',
          contentType: "application/json",
          data: JSON.stringify(value),
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true
        }).then(function (response) {
          data.userHasName = response.userHasName;
          data.userHasPhone = response.userHasPhone;
          resolve(data);
        }, function () {
          reject();
        });
      });
    }
  });

  _exports.default = _default;
});