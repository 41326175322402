define("frontend/transforms/moment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  "use strict";

  var parse = function parse(value) {
    var inputFormat;

    switch (value.length) {
      case 10:
        inputFormat = 'YYYY-MM-DD';
        break;

      case 16:
        inputFormat = 'YYYY-MM-DD HH:mm';
        break;

      case 19:
        inputFormat = 'YYYY-MM-DD HH:mm:ss';
        break;
    }

    return moment(value, inputFormat);
  };

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        return parse(serialized).format();
      }

      return null;
    },
    serialize: function serialize(date) {
      if (date) {
        var m = parse(date);

        if (m && m.isValid()) {
          return m.format();
        }
      }

      return null;
    }
  });

  _exports.default = _default;
});