define("frontend/router", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('auth', function () {
      this.route('2fa');
    });
    this.route('reset-request');
    this.route('reset-response', {
      path: '/reset-response/:user/:key'
    });
    this.route('documents', function () {
      this.route('document', {
        path: '/:document_id'
      });
    });
    this.route('user');
    this.route('settlement-basis');
  });
  var _default = Router;
  _exports.default = _default;
});