define("frontend/templates/reset-response", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fVoWOXAR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n  \"],[1,[28,\"reset-response-form\",null,[[\"user\",\"key\",\"userHasName\",\"userHasPhone\"],[[24,[\"model\",\"user\"]],[24,[\"model\",\"key\"]],[24,[\"model\",\"userHasName\"]],[24,[\"model\",\"userHasPhone\"]]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/reset-response.hbs"
    }
  });

  _exports.default = _default;
});