define("frontend/models/report-queue", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    report: _emberData.default.belongsTo('report'),
    report_config: _emberData.default.attr('object'),
    report_time_inserted: _emberData.default.attr('string'),
    report_time_grabbed: _emberData.default.attr('string'),
    report_time_finished: _emberData.default.attr('string'),
    report_result_content_type: _emberData.default.attr('string'),
    report_result: _emberData.default.attr('string'),
    report_status: _emberData.default.attr('string'),
    report_status_description: _emberData.default.attr('string'),
    message: _emberData.default.attr('boolean'),
    isStatusOk: Ember.computed.equal('report_status', 'ok')
  });

  _exports.default = _default;
});