define("frontend/templates/components/settlement-basis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NJqJatYp",
    "block": "{\"symbols\":[\"row\"],\"statements\":[[7,\"button\",true],[10,\"data-target\",\"avrModal\"],[10,\"class\",\"btn waves-effect waves-light modal-trigger\"],[10,\"style\",\"margin-top:20px;\"],[10,\"type\",\"button\"],[8],[0,\"Avräkningsunderlag\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"id\",\"avrModal\"],[10,\"class\",\"modal\"],[10,\"style\",\"padding:20px;\"],[8],[0,\"\\n    \"],[7,\"table\",true],[10,\"style\",\"width:100%;\"],[8],[0,\"\\n        \"],[7,\"thead\",true],[8],[0,\"\\n        \"],[7,\"tr\",true],[8],[0,\"\\n            \"],[7,\"th\",true],[8],[0,\"Förnamn\"],[9],[0,\"\\n            \"],[7,\"th\",true],[8],[0,\"Efternamn\"],[9],[0,\"\\n            \"],[7,\"th\",true],[8],[0,\"Alder\"],[9],[0,\"\\n            \"],[7,\"th\",true],[8],[0,\"Titel\"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n      \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"rows\"]]],null,{\"statements\":[[0,\"            \"],[7,\"tr\",true],[8],[0,\"\\n                \"],[7,\"td\",true],[8],[1,[23,1,[\"Fornamn\"]],false],[9],[0,\"\\n                \"],[7,\"td\",true],[8],[1,[23,1,[\"Efternamn\"]],false],[9],[0,\"\\n                \"],[7,\"td\",true],[8],[1,[23,1,[\"Alder\"]],false],[9],[0,\"\\n                \"],[7,\"td\",true],[8],[1,[23,1,[\"Titel\"]],false],[9],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"script\",true],[8],[0,\"\\n    $(document).ready(function(){\\n        $('.avrModal').modal();\\n    });\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/settlement-basis.hbs"
    }
  });

  _exports.default = _default;
});