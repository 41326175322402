define("frontend/components/user-profile-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    errors: Ember.computed('user.errors.@each.attribute', function () {
      var _this = this;

      var errors = this.get('user.errors') || [];
      return errors.filter(function (error) {
        return error.attribute === _this.get('property');
      });
    })
  });

  _exports.default = _default;
});