define("frontend/transforms/datetime", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      // Convert from UTC to local timezone for display purposes
      if (serialized) {
        return moment.utc(serialized).local().format('YYYY-MM-DD HH:mm:ss');
      }
    },
    serialize: function serialize(deserialized) {
      // Convert back to UTC for storage
      if (deserialized) {
        return moment(deserialized).utc().format('YYYY-MM-DD HH:mm:ss');
      }
    }
  });

  _exports.default = _default;
});