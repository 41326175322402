define("frontend/routes/reset-request", ["exports", "frontend/routes/route", "frontend/utils/is-authenticated"], function (_exports, _route, _isAuthenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    redirect: function redirect() {
      var _this = this;

      (0, _isAuthenticated.default)().then(function () {
        _this.transitionTo('documents');
      });
    }
  });

  _exports.default = _default;
});