define("frontend/components/date-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['input-smaller', 'input-date'],
    format: 'YYYY-MM-DD',
    valid: true,
    internal: Ember.computed('value', {
      'get': function get() {
        return this._format(this.get('value'));
      },
      'set': function set(key, value) {
        var valid = this._validate(value);

        this.set('valid', valid);

        if (valid) {
          this._set(value);
        } else if (value.length === 0) {
          this.set('value');
        }

        return value;
      }
    }),
    _format: function _format(value) {
      if (typeof value !== 'undefined') {
        if (value.includes('...')) {
          var parts = value.split('...');
          var firstPartMoment = moment(this._formattedRangeDate(parts[0]));
          var secondPartMoment = moment(this._formattedRangeDate(parts[1]));

          if (firstPartMoment && firstPartMoment.isValid() && secondPartMoment && secondPartMoment.isValid()) {
            return firstPartMoment.format('YYYY-MM-DD') + '...' + secondPartMoment.format('YYYY-MM-DD');
          }
        }
      }

      var valueMoment = moment(value);

      if (value && valueMoment && valueMoment.isValid()) {
        return valueMoment.format('YYYY-MM-DD');
      }

      return null;
    },
    _formattedRangeDate: function _formattedRangeDate(value) {
      var dateParts = value.match(/[0-9]{2}/g);
      if (dateParts.length !== 3) return null;
      return '20' + dateParts[0] + '-' + dateParts[1] + '-' + dateParts[2];
    },
    _getTime: function _getTime() {
      var time = {
        hours: 0,
        minutes: 0,
        seconds: 0
      };
      var value = this.get('time');
      var isStringValue = value && typeof value === 'string';

      if (isStringValue) {
        var colons = (value.match(/:/g) || []).length;
        var format;

        if (colons === 1) {
          format = 'HH:mm';
        } else if (colons === 2) {
          format = 'HH:mm:ss';
        }

        if (format) {
          var valueMoment = moment(value, format);

          if (valueMoment.isValid()) {
            time.hours = valueMoment.hours();
            time.minutes = valueMoment.minutes();
            time.seconds = valueMoment.seconds();
          }
        }
      }

      return time;
    },
    _set: function _set(input) {
      var parsed = this._parse(input); // Workaround to support date ranges


      if (parsed === null && this.allowRange) {
        return this.set('value', input);
      }

      var value = this.get('value');
      var wasSet = !!value;
      var forceTime = !!this.get('time');
      value = moment(value);
      value.year(parsed[0]);
      value.month(parsed[1] - 1);
      value.date(parsed[2]);

      if (!wasSet || forceTime) {
        var time = this._getTime();

        value.minutes(time.minutes);
        value.hours(time.hours);
        value.seconds(time.seconds);
      }

      this.set('value', value.format(this.get('format')));
    },
    _parse: function _parse(input) {
      var parsed = input.match(/^([0-9]{4})-?([0-9]{2})-?([0-9]{2})$/);

      if (!parsed) {
        if (input.length === 6 || input.length === 8) {
          return this._parse('20' + input);
        } // Tells us this value should be set as is


        return null;
      }

      parsed.shift();
      return parsed;
    },
    _getStateElement: function _getStateElement() {
      return this.$().find('input');
    },
    _getInput: function _getInput() {
      return this.$().find('input');
    },
    _validate: function _validate(value) {
      var dateFormat = !!value.match(/^[0-9]{4}-?[0-9]{2}-?[0-9]{2}$/);

      if (!dateFormat) {
        if (value.length === 6 || value.length === 8) {
          return this._validate('20' + value);
        }

        if (value.indexOf('...') !== -1) {
          var parts = value.split(/\.\.\./);
          return this._validate(parts[0]) && this._validate(parts[1]);
        }

        return false;
      }

      var m = moment(value);
      return m && m.isValid();
    },
    _setup: Ember.on('didInsertElement', function () {
      this.$().on('blur', 'input', this._setClasses.bind(this));
      this.$().removeClass();
    }),
    _setClasses: function _setClasses() {
      var valid = this.get('valid');
      var value = this.get('internal');
      var empty = !value || value.length === 0;

      var $wrapper = this._getStateElement();

      var focused = this._getInput().is(':focus'); // Clear first


      $wrapper.removeClass('has-success').removeClass('has-warning').removeClass('invalid');
      this.set('error', false);

      if (empty) {
        return;
      }

      if (valid) {
        $wrapper.addClass('has-success');
      } else if (focused) {
        $wrapper.addClass('has-warning');
      } else {
        $wrapper.addClass('invalid');
        this.set('error', true);
      } // Never leave the success class when not in focus


      if (!focused) {
        $wrapper.removeClass('has-success');
      }
    },
    validObserver: Ember.observer('valid', function () {
      this._setClasses();
    })
  });

  _exports.default = _default;
});