define("frontend/adapters/application", ["exports", "ember-data", "frontend/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend({
    router: Ember.inject.service(),
    isInvalid: function isInvalid(resp) {
      if (resp === 401) {
        this.get('router').transitionTo('auth');
      }

      return this._super(resp);
    },
    namespace: 'api',
    host: _environment.default.APP.apiHost,
    ajax: function ajax(url, method) {
      var hash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      hash.xhrFields = {
        withCredentials: true
      };
      return this._super(url, method, hash);
    }
  });

  _exports.default = _default;
});