define("frontend/routes/user", ["exports", "frontend/routes/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    model: function model() {
      return this.store.queryRecord('user', {
        me: true
      });
    }
  });

  _exports.default = _default;
});