define("frontend/components/reset-request-form", ["exports", "jquery", "frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      requestResetAction: function requestResetAction() {
        var _this = this;

        var host = _environment.default.APP.apiHost;
        var value = {
          resetRequest: {
            email: this.email
          }
        };

        _jquery.default.ajax(host + '/api/reset/request/', {
          type: 'POST',
          contentType: "application/json",
          data: JSON.stringify(value),
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true
        }).then(function () {
          var message = 'E-post med återställningslänk har skickats till ' + _this.email;
          M.toast({
            html: message,
            classes: 'green darken-1 rounded'
          });
        }, function () {
          M.toast({
            html: 'Angiven e-postadress finns inte registrerad',
            classes: 'red darken-1 rounded'
          });
        });
      }
    }
  });

  _exports.default = _default;
});