define("frontend/components/scroll-to-current-item", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var $ = _jquery.default;

  var _default = Ember.Component.extend({
    start: Ember.on('didInsertElement', function () {
      if (this.index > 0) {
        var $currentItem = $('.documentListTableBody .current-item');
        var $contentWrapper = $('.content-wrapper');
        var currentItemOffset = $currentItem.offset();

        if (currentItemOffset) {
          var offsetTop = $contentWrapper.scrollTop() + currentItemOffset.top;
          var viewportHeight = $(window).height();
          offsetTop -= viewportHeight / 2;
          $contentWrapper.scrollTop(offsetTop);
        }
      }
    })
  });

  _exports.default = _default;
});