define("frontend/templates/components/user-profile-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RhleVGuo",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[7,\"label\",true],[11,\"for\",[29,[[22,\"property\"]]]],[8],[1,[22,\"label\"],false],[0,\" \"],[4,\"if\",[[24,[\"required\"]]],null,{\"statements\":[[0,\"*\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[1,[28,\"input\",null,[[\"class\",\"value\",\"id\",\"required\"],[[28,\"if\",[[24,[\"errors\",\"length\"]],\"invalid\"],null],[28,\"get\",[[24,[\"user\"]],[24,[\"property\"]]],null],[24,[\"property\"]],[24,[\"required\"]]]]],false],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"errors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"helper-text\"],[11,\"data-error\",[29,[[23,1,[\"message\"]]]]],[10,\"data-success\",\"right\"],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/user-profile-input.hbs"
    }
  });

  _exports.default = _default;
});