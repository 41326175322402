define("frontend/templates/components/document-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aj5sp7qe",
    "block": "{\"symbols\":[\"filter\"],\"statements\":[[4,\"each\",[[24,[\"availableFilters\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"activatedFilter\"]],[23,1,[\"filter_display_name\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"button\",false],[12,\"class\",\"btn currentFilter\"],[3,\"action\",[[23,0,[]],\"filterAction\",[23,1,[]]]],[8],[1,[23,1,[\"filter_display_name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"button\",false],[12,\"class\",\"btn\"],[3,\"action\",[[23,0,[]],\"filterAction\",[23,1,[]]]],[8],[1,[23,1,[\"filter_display_name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/document-filter.hbs"
    }
  });

  _exports.default = _default;
});