define("frontend/components/reset-response-form", ["exports", "jquery", "frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    type: "password",
    passwordErrors: [],
    passwordMinLength: 10,
    nameErrorMessage: '',
    phoneErrorMessage: '',
    isCompletionRequired: Ember.computed('userHasName', 'userHasPhone', function () {
      return !this.userHasName || !this.userHasPhone;
    }),
    actions: {
      suggestPasswordAction: function suggestPasswordAction() {
        var suggestion = Math.random().toString(36).slice(-1 * this.passwordMinLength);
        this.set('type', 'text');
        this.set('password1', suggestion);
        this.set('password2', suggestion);
        this.set('errors', []);
      },
      resetAction: function resetAction() {
        this.set('errors', []);
        var password1 = this.password1 || '';
        var password2 = this.password2 || '';
        var name = this.name || '';
        var phone = this.phone || '';

        if (!password1) {
          this.errors.push('Du måste ange ett nytt lösenord');
        }

        if (password1 !== password2) {
          this.errors.push('Lösenorden stämmer inte överens');
        }

        if (password1.length < this.passwordMinLength) {
          this.errors.push('Lösenordet måste vara minst ' + this.passwordMinLength + ' tecken långt');
        }

        if (!this.userHasName) {
          if (name.length === 0) {
            this.set('nameErrorMessage', 'Namn måste anges');
          } else if (name.indexOf(' ') === -1) {
            this.set('nameErrorMessage', 'Du måste ange både för- och efternamn');
          } else if (name.length < 5) {
            this.set('nameErrorMessage', 'Du måste ange ett fullständigt namn som är minst fem tecken långt');
          } else {
            this.set('nameErrorMessage');
          }
        }

        if (!this.userHasPhone) {
          if (phone.length === 0) {
            this.set('phoneErrorMessage', 'Telefonnummer måste anges');
          } else if (phone.length < 7) {
            this.set('phoneErrorMessage', 'Du måste ange ett korrekt telefonnummer på minst 7 siffror');
          } else {
            this.set('phoneErrorMessage');
          }
        }

        if (this.errors.length === 0 && !this.nameErrorMessage && !this.phoneErrorMessage) {
          var host = _environment.default.APP.apiHost;
          var value = {
            reset: {
              password: password1,
              user: this.user,
              key: this.key
            },
            user: {
              name: this.name,
              phone: this.phone
            }
          };
          var that = this;

          _jquery.default.ajax(host + '/api/reset/set/', {
            type: 'POST',
            contentType: "application/json",
            data: JSON.stringify(value),
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            success: function success() {
              that.set('isResetPasswordComplete', true);
            },
            error: function error(jqXHR) {
              var response = jqXHR.responseJSON;

              if (response && !response.isValid) {
                that.set('passwordErrors', response.errors);
              }

              M.toast({
                html: 'Det gick inte att återställa ditt lösenord',
                classes: 'red darken-1 rounded'
              });
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});