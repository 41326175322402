define("frontend/components/quick-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    advancedSearchData: Ember.inject.service(),
    query: '',
    settlementFsnr: null,
    settlementData: [],
    settlementModalOpen: false,
    actions: {
      search: function search() {
        var _this = this;

        document.getElementById('advanced-search-form').reset();
        this.advancedSearchData.clearOnOtherSearch();
        this.set('activatedFilter', '');
        this.set('index', 0);
        this.documents.removeObjects(this.documents);
        this.set('isLoading', true);
        this.set('documentQuery', this.query);
        this.set('documentFilter', '');
        this.set('isLoadingMoreStopped', false);
        this.set('offset', 1);
        this.updateDocumentCount();
        this.updateDownloadLink();
        this.get('user').then(function (user) {
          var query = {
            query: _this.query,
            owner_id: user.get('owner_id')
          };

          _this.store.query('document', query).then(function (documents) {
            _this.documents.pushObjects(documents.toArray());

            _this.updateDocumentCount(query);

            _this.updateDownloadLink(query);

            _this.set('isLoading', false);

            if (documents.length === 0) {
              _this.lookForSettlementData(query, _this);
            }

            if (documents.length === 1) {
              _this.router.transitionTo('documents.document', documents.firstObject.id);
            } else {
              _this.router.transitionTo('documents.index');
            }
          });
        });
      },
      insertNewline: function insertNewline() {
        Ember.$('#quick-search').click();
      }
    }
  });

  _exports.default = _default;
});