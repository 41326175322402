define("frontend/services/permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    permission: null,
    owner_id: null
  });

  _exports.default = _default;
});