define("frontend/helpers/br", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function br(params, hash) {
    var text = params[0];
    text = text.replace(/(\r\n|\n|\r)/gm, '<br>');
    return new Ember.String.htmlSafe(text);
  });

  _exports.default = _default;
});