define("frontend/templates/documents/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U9eksb56",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"document-list\",null,[[\"documents\",\"index\",\"ownerAttributes\",\"isLoading\",\"isDebugging\",\"loadMoreDocuments\"],[[24,[\"model\"]],[24,[\"index\"]],[24,[\"application\",\"ownerAttributes\"]],[24,[\"documents\",\"isLoading\"]],[24,[\"application\",\"isDebugging\"]],[28,\"action\",[[23,0,[]],\"loadMoreDocuments\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/documents/index.hbs"
    }
  });

  _exports.default = _default;
});