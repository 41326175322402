define("frontend/routes/documents", ["exports", "frontend/routes/route", "ember-keyboard-shortcuts", "frontend/config/environment", "jquery"], function (_exports, _route, _emberKeyboardShortcuts, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = _route.default.extend({
    router: Ember.inject.service(),
    currentAttrIndex: Ember.on('didReceiveAttrs', function () {
      return $().val($(':input:focus').index());
    }),
    keyboardShortcuts: {
      up: {
        action: 'prevDocumentAction',
        global: true
      },
      down: {
        action: 'nextDocumentAction',
        global: true
      },
      '? ! ? ! ? !': {
        action: 'switchDebug',
        global: false
      }
    },
    activate: function activate() {
      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
    },
    deactivate: function deactivate() {
      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
    },
    transitionToDocument: function transitionToDocument(onFulfilled, onRejected) {
      var controller = this.controllerFor('documents');
      var documents = controller.get('documents');
      var document = documents[controller.index];

      if (document) {
        return this.transitionTo('documents.document', document).then(onFulfilled, onRejected);
      } else if (onRejected && typeof onRejected === 'function') {
        onRejected();
      }
    },
    getSubject: function getSubject(document, attributeValues, ownerAttributes) {
      var map = [];
      var subject;
      ownerAttributes.forEach(function (ownerAttribute) {
        map[ownerAttribute.get('attr.id')] = ownerAttribute.get('attr.attr_name');
      });
      attributeValues.forEach(function (attributeValue) {
        var attributeName = map[attributeValue.get('attr.id')];

        if (attributeName === 'Fsnr') {
          subject = attributeValue.get('value');
        }
      });

      if (!subject) {
        attributeValues.forEach(function (attributeValue) {
          var attributeName = map[attributeValue.get('attr.id')];

          if (attributeName === 'Fndnr') {
            subject = attributeValue.get('value');
          }
        });
      }

      if (!subject) {
        attributeValues.forEach(function (attributeValue) {
          var attributeName = map[attributeValue.get('attr.id')];

          if (attributeName === 'Sndnr') {
            subject = attributeValue.get('value');
          }
        });
      }

      if (!subject) {
        subject = document.get('id').replace(/:/, '-');
      }

      return subject;
    },
    actions: {
      openForm: function openForm() {
        var _this = this;

        var documentsController = this.controllerFor('documents');
        var applicationController = this.controllerFor('application');
        var documentController = this.controllerFor('documents.document');
        documentsController.set('subject', $('.document-attributes input:nth-child(2)').val());
        documentsController.set('message');
        documentsController.set('isEmailInvalid', false);
        documentsController.set('isSubjectInvalid', false);
        documentsController.set('isMessageInvalid', false);
        documentsController.set('isEmailSending', false);
        documentsController.set('emailSendError');
        applicationController.get('user').then(function (user) {
          var latestContacts = user.get('settings.latestContacts') || [];

          if (latestContacts[latestContacts.length - 1] !== 'Ny mottagare') {
            latestContacts.push('Ny mottagare');
          }

          documentsController.set('latestContacts', latestContacts);
          documentsController.set('email', documentsController.get('latestContact'));
        });
        var document = documentController.get('model');
        var ownerAttributes = applicationController.get('ownerAttributes');
        documentController.get('model.attr_values').then(function (attributeValues) {
          documentsController.set('subject', _this.getSubject(document, attributeValues, ownerAttributes));
        });
      },
      closeMail: function closeMail() {
        $('#modal1').modal('close');
      },
      sendMail: function sendMail() {
        var model = this.controllerFor('documents.document').get('model');
        var controller = this.controllerFor('documents');
        var application = this.controllerFor('application');

        if (model) {
          var email = controller.get('newContactSelected') ? controller.get('newEmail') : controller.get('email');
          var subject = controller.get('subject');
          var message = controller.get('message');
          controller.set('isEmailInvalid', !email || !email.length);
          controller.set('isSubjectInvalid', !subject || !subject.length);

          if (controller.isEmailInvalid || controller.isSubjectInvalid) {
            return;
          }

          var user = application.get('user');
          var formdata = {
            to: email,
            subject: subject,
            message: message,
            owner_id: user.get('owner_id')
          };
          var latestContacts = controller.get('latestContacts');
          var indexOfEmail = latestContacts.indexOf(email);

          if (indexOfEmail !== -1) {
            // Removing selected contact from wherever he is so that we can put him back on top
            latestContacts.splice(indexOfEmail, 1);
          }

          latestContacts.splice(latestContacts.length - 1, 1); // Remove the "Ny mottagare" option
          // Constraining array to a length of 10

          latestContacts = [email].concat(_toConsumableArray(latestContacts)).splice(0, 10);
          latestContacts.push('Ny mottagare');
          controller.set('latestContacts', latestContacts);
          user.then(function (u) {
            u.set('settings.latestContacts', latestContacts);
            u.save();
          });
          controller.set('isEmailSending', true);
          controller.set('emailSendError');

          _jquery.default.ajax(_environment.default.APP.apiHost + '/mail/' + model.get('id'), {
            type: 'POST',
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            data: JSON.stringify(formdata),
            contentType: 'application/json',
            success: function success() {
              $('#modal1').modal('close');
              M.toast({
                html: 'E-post har skickats',
                classes: 'green darken-1 rounded'
              });
              controller.set('isEmailSending', false);
            },
            error: function error(response) {
              controller.set('isEmailSending', false);
              var message = 'Ett fel uppstod när mailet skulle skickas.';

              if (response.responseText && response.responseText !== 'Internal Server Error') {
                message = 'Ett fel uppstod när mailet skulle skickas: ' + response.responseText;
              }

              controller.set('emailSendError', message);
              M.toast({
                html: message,
                classes: 'red darken-1 rounded'
              });
            }
          });
        }
      },
      nextDocumentAction: function nextDocumentAction() {
        var controller = this.controllerFor('documents');
        var index = controller.get('index');
        var documentsLength = controller.get('documents').length;
        var lastDocumentIndex = documentsLength - 1;

        if (index < lastDocumentIndex) {
          controller.incrementProperty('index');
        } else if (index === lastDocumentIndex) {
          controller.loadMoreDocuments().then(function () {
            controller.incrementProperty('index');
          });
        }
      },
      prevDocumentAction: function prevDocumentAction() {
        var controller = this.controllerFor('documents');

        if (!controller.get('index') <= 0) {
          controller.decrementProperty('index');
        }
      },
      gotoNextPageAction: function gotoNextPageAction() {
        var documentController = this.controllerFor('documents.document');
        documentController.send('nextPageAction');
      },
      gotoPrevPageAction: function gotoPrevPageAction() {
        var documentController = this.controllerFor('documents.document');
        documentController.send('prevPageAction');
      },
      printAction: function printAction() {
        var controller = this.controllerFor('documents/document');
        var doc_id = controller.get('model.id');
        var iframe = document.getElementById('doc_iframe');

        if (!iframe) {
          iframe = document.createElement('iframe');
          iframe.id = 'doc_iframe';
          iframe.style.display = 'none';
          $('body').append(iframe);
        }

        iframe.src = _environment.default.APP.apiHost + '/print/' + doc_id;

        iframe.onload = function () {
          try {
            iframe.contentWindow.print();
          } catch (e) {
            M.toast({
              html: e.message,
              classes: 'red darken-1 rounded'
            });
          }
        };
      },
      firstDocumentAction: function firstDocumentAction() {
        var controller = this.controllerFor('documents');
        var currentIndex = controller.get('index');
        controller.set('index', 0);
        this.transitionToDocument(undefined, function () {
          controller.set('index', currentIndex);
        });
      },
      lastDocumentAction: function lastDocumentAction() {
        var controller = this.controllerFor('documents');
        var currentIndex = controller.get('index');
        var documentsLength = controller.get('documents').length;
        controller.set('index', documentsLength - 1);
        this.transitionToDocument(undefined, function () {
          controller.set('index', currentIndex);
        });
      },
      transitionToDocument: function transitionToDocument() {
        this.transitionToDocument();
      },
      zoomImgIn: function zoomImgIn() {
        var controller = this.controllerFor('documents');

        if (controller.get('zoomValue') < 2) {
          controller.incrementProperty('zoomValue');
        } else if (controller.get('zoomValue') === 2) {
          controller.set('zoomValue', 0);
        }
      },
      zoomImgOut: function zoomImgOut() {
        var controller = this.controllerFor('documents');

        if (controller.get('zoomValue') > 0) {
          controller.decrementProperty('zoomValue');
        }
      },
      rotate: function rotate() {
        var controller = this.controllerFor('documents/document');
        var rotatedDegrees = controller.get('rotatedDegrees');

        switch (rotatedDegrees) {
          case 0:
            rotatedDegrees = 180;
            break;

          case 90:
            rotatedDegrees = 270;
            break;

          case 180:
            rotatedDegrees = 90;
            break;

          case 270:
            rotatedDegrees = 0;
            break;
        }

        controller.set('rotatedDegrees', rotatedDegrees);
        this.send('preloadNextUnlockedDocumentAction', rotatedDegrees);
      },
      switchDebug: function switchDebug() {
        this.controllerFor('application').toggleProperty('isDebugging');
      }
    }
  });

  _exports.default = _default;
});