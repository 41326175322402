define("frontend/templates/components/document-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ndpacm42",
    "block": "{\"symbols\":[\"ownerAttribute\",\"index\",\"attr_value\",\"avrstat\"],\"statements\":[[4,\"link-to\",null,[[\"class\",\"tagName\",\"route\",\"model\"],[[24,[\"className\"]],\"tr\",\"documents.document\",[24,[\"document\"]]]],{\"statements\":[[0,\"    \"],[7,\"td\",true],[8],[0,\"\\n      \"],[1,[24,[\"document\",\"scan_date\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"ownerAttributes\"]]],null,{\"statements\":[[0,\"        \"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"document\",\"attr_values\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,3,[\"attr\",\"id\"]],[23,1,[\"attr\",\"id\"]]],null]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,3,[\"attr\",\"id\"]],\"11\"],null]],null,{\"statements\":[[4,\"each\",[[24,[\"avrstats\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,4,[\"value\"]],[23,3,[\"value\"]]],null]],null,{\"statements\":[[0,\"                    \"],[1,[23,4,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[4]},null]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[1,[23,3,[\"value\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[3]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"isDebugging\"]]],null,{\"statements\":[[0,\"      \"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"document\",\"lock\"]]],null,{\"statements\":[[0,\"          \"],[7,\"i\",true],[10,\"class\",\"tiny material-icons\"],[11,\"title\",[29,[\"Låst av \",[24,[\"document\",\"lock\",\"user\",\"name\"]],\" vid \",[28,\"datetime\",[[24,[\"document\",\"lock\",\"created\"]]],null]]]],[8],[0,\"lock\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/document-list-item.hbs"
    }
  });

  _exports.default = _default;
});