define("frontend/models/document", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    owner_id: attr('number'),
    scan_date: attr('string'),
    scan_time: attr('string'),
    image_type: attr('number'),
    file_time: attr('string'),
    ingest_time: attr('string'),
    deleted: attr('boolean'),
    trpnr: attr('string'),
    page_count: attr('number'),
    file_name: attr('string'),
    hash: attr('string'),
    owner: _emberData.default.belongsTo('owner'),
    lock: _emberData.default.belongsTo('document-lock'),
    attr_values: _emberData.default.hasMany('attribute-value'),
    url: Ember.computed('file_name', 'hash', function () {
      var filename = this.file_name;
      var hash = this.hash;
      return "https://image.sadata.se/v1/".concat(hash, "/").concat(filename, "?type=jpg");
    }),
    downloadUrl: Ember.computed('file_name', 'hash', function () {
      var filename = this.file_name;
      var hash = this.hash;
      return "https://image.sadata.se/v1/".concat(hash, "/").concat(filename, "?type=pdf&altname=").concat(filename, "&download=1");
    }),
    isLastPage: false,
    isFirstPage: true,
    hasMultiplePages: Ember.computed.gt('page_count', 1),
    hasChanged: Ember.computed.gt('changedAttributes.length', 0),
    changedAttributes: Ember.computed.filterBy('attr_values', 'hasDirtyAttributes'),
    invalidAttributes: Ember.computed.filterBy('attr_values', 'invalid'),
    hasInvalidAttributes: Ember.computed.gt('invalidAttributes.length', 0),
    hasInvalidCleanAttributes: Ember.computed.filterBy('attr_values', 'invalidAndClean'),
    isAttributesValid: Ember.computed.not('hasInvalidAttributes'),
    isValidated: Ember.computed.lt('hasInvalidCleanAttributes.length', 1),
    avrstat: Ember.computed('attr_values.@each.attr.id', 'attr_values.@each.value', function () {
      var attributeValue = this.get('attr_values').findBy('attr.id', '11');

      if (!attributeValue) {
        return null;
      }

      return attributeValue.get('value');
    }),
    statusColor: Ember.computed('hasInvalidAttributes', 'avrstat', function () {
      if (this.get('hasInvalidAttributes')) {
        return 'row-red';
      }

      var avrstat = this.get('avrstat');

      if (['1', '2', '3'].indexOf(avrstat) !== -1) {
        return 'row-yellow';
      }

      if (['4', '5', '6'].indexOf(avrstat) !== -1) {
        return 'row-white';
      }

      return 'row-green';
    }),
    serverCreatedAttributeValues: Ember.computed.filter('attr_values', function (attributeValue) {
      // Attribute values created by the server has a year delimiter in the ID
      return attributeValue.get('id').indexOf(':') !== -1;
    }),
    changedServerCreatedAttributeValues: Ember.computed.filterBy('serverCreatedAttributeValues', 'hasDirtyAttributes'),
    hasChangedServerCreatedAttributes: Ember.computed.gt('changedServerCreatedAttributeValues.length', 0),
    clientCreatedAttributeValues: Ember.computed.filter('attr_values', function (attributeValue) {
      // Attribute values created by the server has a year delimiter in the ID
      return attributeValue.get('id').indexOf(':') === -1;
    }),
    changedClientCreatedAttributeValues: Ember.computed.filterBy('clientCreatedAttributeValues', 'isClientCreatedChanged'),
    hasChangedClientCreatedAttributes: Ember.computed.gt('changedClientCreatedAttributeValues.length', 0),
    isAttributeValuesDirty: Ember.computed.or('hasChangedServerCreatedAttributes', 'hasChangedClientCreatedAttributes'),
    isDirty: Ember.computed.or('hasDirtyAttributes', 'isAttributeValuesDirty'),
    rollback: function rollback() {
      this.rollbackAttributes();
      return this.get('attr_values').then(function (attributeValues) {
        while (attributeValues.filterBy('hasDirtyAttributes').get('length') > 0) {
          attributeValues.filterBy('hasDirtyAttributes').get('firstObject').rollbackAttributes();
        }

        return true;
      });
    },
    getAllProperties: function getAllProperties() {
      return {
        owner_id: this.owner_id,
        scan_date: this.scan_date,
        scan_time: this.scan_time,
        image_type: this.image_type,
        file_time: this.file_time,
        ingest_time: this.ingest_time,
        deleted: this.deleted,
        trpnr: this.trpnr,
        page_count: this.page_count,
        hasMultiplePages: this.hasMultiplePages,
        isLastPage: this.isLastPage,
        isFirstPage: this.isFirstPage,
        file_name: this.file_name,
        hash: this.hash,
        owner: this.owner,
        lock: this.lock,
        attr_values: this.attr_values,
        url: this.url,
        downloadUrl: this.downloadUrl,
        hasChanged: this.hasChanged,
        changedAttributes: this.changedAttributes,
        invalidAttributes: this.invalidAttributes,
        hasInvalidAttributes: this.hasInvalidAttributes,
        hasInvalidCleanAttributes: this.hasInvalidCleanAttributes,
        isAttributesValid: this.isAttributesValid,
        isValidated: this.isValidated,
        avrstat: this.avrstat,
        statusColor: this.statusColor,
        serverCreatedAttributeValues: this.serverCreatedAttributeValues,
        changedServerCreatedAttributeValues: this.changedServerCreatedAttributeValues,
        hasChangedServerCreatedAttributes: this.hasChangedServerCreatedAttributes,
        clientCreatedAttributeValues: this.clientCreatedAttributeValues,
        isAttributeValuesDirty: this.isAttributeValuesDirty,
        isDirty: this.isDirty
      };
    }
  });

  _exports.default = _default;
});