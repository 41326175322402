define("frontend/templates/components/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z46JHXHi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"condition\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\",true],[10,\"class\",\"dropdown-trigger\"],[10,\"href\",\"#!\"],[11,\"data-target\",[29,[[22,\"id\"]]]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"large material-icons left\"],[8],[1,[22,\"icon\"],false],[9],[0,\"\\n    \"],[1,[22,\"label\"],false],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"material-icons right\"],[8],[0,\"arrow_drop_down\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"ul\",true],[11,\"id\",[29,[[22,\"id\"]]]],[10,\"class\",\"dropdown-content\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/dropdown.hbs"
    }
  });

  _exports.default = _default;
});