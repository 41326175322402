define("frontend/models/owner", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    owner_id: _emberData.default.attr('string'),
    owner_identity: _emberData.default.attr('string'),
    owner_name: _emberData.default.attr('string'),
    owner_city: _emberData.default.attr('string'),
    trpnrs: _emberData.default.attr('array'),
    assignments: _emberData.default.attr('array'),
    hasTransporterNumbers: _emberData.default.attr('boolean'),
    hasFewTransporterNumbers: Ember.computed.lt('trpnrs.length', 11) // <=10

  });

  _exports.default = _default;
});