define("frontend/routes/documents/document", ["exports", "frontend/routes/route", "ember-keyboard-shortcuts", "jquery"], function (_exports, _route, _emberKeyboardShortcuts, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var $ = _jquery.default;
  Mousetrap.addKeycodes({
    111: 'numpadDiv',
    106: 'numpadAsterisk'
  });

  var _default = _route.default.extend({
    routeActive: false,
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      var documentsController = this.controllerFor('documents'); // Reset rotation on document load

      documentsController.set('rotatedDegrees', 0);
      var currentIndex = documentsController.get('index');
      var documents = documentsController.get('documents');
      var correctIndex = documents.indexOf(model);
      documentsController.set('showDocBtns', true); // Update parent controller index to this document

      if (correctIndex !== currentIndex && correctIndex > -1) {
        documentsController.set('index', correctIndex);
      } // This is true if you browse directly to a document without performing a search
      // For example, by pressing a link to the document


      if (correctIndex === -1) {
        documentsController.set('currentDocument', model);
      } else {
        documentsController.set('currentDocument', documents.objectAt(correctIndex));
      }

      if (this.get('routeActive')) {
        model.reload().then(function (document) {
          document.get('lock').then(function (lock) {
            if (!lock) {
              if (_this.get('routeActive')) {
                _this.createLock(document);
              }
            } else {
              lock.reload().then(function () {// Lock still exists, do nothing
              }, function () {
                // Lock has been removed, create a new lock for me
                if (_this.get('routeActive')) {
                  _this.createLock(document);
                }
              });
            }
          });
        });
      }
    },
    createLock: function createLock(document) {
      var _this2 = this;

      var rotatedDegrees = 0;
      var documentController = this.controllerFor('documents/document');

      if (documentController) {
        rotatedDegrees = documentController.get('rotatedDegrees');
      }

      this.send('preloadNextUnlockedDocumentAction', rotatedDegrees);
      var applicationController = this.controllerFor('application');
      this.incrementProperty('lockCount');
      var lockData = {
        document: document,
        instance: applicationController.get('instance'),
        count: this.get('lockCount')
      };
      applicationController.get('user').then(function (user) {
        lockData.user = user;

        var lock = _this2.store.createRecord('document-lock', lockData);

        document.set('lock', lock);
        lock.save();
      });
    },
    keyboardShortcuts: {
      enter: {
        action: 'nextDocumentEnterAction',
        global: true
      },
      esc: {
        action: 'backToList',
        global: true
      },
      'ctrl+s': {
        action: 'saveDocToListAction',
        global: true
      },
      'ctrl+v': {
        action: 'saveCopyOfDocAction',
        global: true
      },
      numpadDiv: {
        action: 'rotate',
        global: true,
        eventType: 'keydown'
      },
      numpadAsterisk: {
        action: 'zoomImgIn',
        global: true,
        eventType: 'keydown'
      }
    },
    activate: function activate() {
      this.set('routeActive', true);
      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
    },
    deactivate: function deactivate() {
      this.set('routeActive', false);
      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
    },
    transitionToDocument: function transitionToDocument(onFulfilled, onRejected) {
      var controller = this.controllerFor('documents');
      var documents = controller.get('documents');
      var document = documents[controller.get('index')];

      if (document) {
        return this.transitionTo('documents.document', document).then(onFulfilled, onRejected);
      } else if (onRejected && typeof onRejected === 'function') {
        onRejected();
      }
    },
    cacheImageOfNextDocument: function cacheImageOfNextDocument(rotatedDegrees) {
      var controller = this.controllerFor('documents');
      var documents = controller.get('documents');
      var currentIndex = controller.get('index');
      if (!rotatedDegrees) rotatedDegrees = 0;
      var nextIndex = currentIndex + 1;
      var nextDocument = documents[nextIndex];

      if (nextDocument) {
        var hasAlreadyPreloaded = false;
        var nextImageUrl = nextDocument.url + '&rotate=' + rotatedDegrees + '&page=1';
        var alreadyPreloadedImages = JSON.parse(sessionStorage.getItem('preloadedImages'));

        if (Array.isArray(alreadyPreloadedImages) && alreadyPreloadedImages.length) {
          hasAlreadyPreloaded = alreadyPreloadedImages.indexOf(nextImageUrl) !== -1;
        } else {
          alreadyPreloadedImages = [];
        }

        if (!hasAlreadyPreloaded) {
          alreadyPreloadedImages.push(nextImageUrl);
          sessionStorage.setItem('preloadedImages', JSON.stringify(alreadyPreloadedImages));
          var img = new Image();
          img.src = nextImageUrl;
        }
      }
    },
    actions: {
      unbindRouteEscapeKey: function unbindRouteEscapeKey() {
        // This method travels from the info-box component to this route
        (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
        delete this.keyboardShortcuts.esc;
        (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
      },
      rebindRouteEscapeKey: function rebindRouteEscapeKey() {
        // This method travels from the info-box component to this route
        (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
        this.keyboardShortcuts.esc = {
          action: 'backToList',
          global: true
        };
        (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
      },
      preloadNextUnlockedDocumentAction: function preloadNextUnlockedDocumentAction(rotatedDegrees) {
        var _this3 = this;

        var controller = this.controllerFor('documents');
        var currentIndex = controller.get('index');
        var documents = controller.get('documents');
        var lastDocumentIndex = documents.length - 1;

        if (currentIndex === lastDocumentIndex) {
          controller.loadMoreDocuments().then(function () {
            _this3.cacheImageOfNextDocument(rotatedDegrees);
          });
        } else {
          this.cacheImageOfNextDocument(rotatedDegrees);
        }
      },
      nextUnlockedDocumentAction: function nextUnlockedDocumentAction(rotatedDegrees) {
        var _this4 = this;

        var controller = this.controllerFor('documents');
        var index = controller.get('index');
        var currentIndex = index;
        var documents = controller.get('documents');
        var documentsLength = documents.length;
        var lastDocumentIndex = documentsLength - 1;

        var tryNext = function tryNext() {
          var documents = controller.get('documents');
          var documentsLength = documents.length;
          var lastDocumentIndex = documentsLength - 1; // If we've reached the end of the list of documents

          if (controller.get('index') === lastDocumentIndex) {
            return controller.loadMoreDocuments().then(tryNext, function () {
              // Reset index if unable to load more documents
              controller.set('index', currentIndex);
            });
          }

          var newIndex = controller.incrementProperty('index');
          var newDocument = documents[newIndex]; // Try next document by reloading to check it's state

          return newDocument.reload().then(function (newDocument) {
            // Check if lock exists and is still there by fetching and reloading
            return newDocument.get('lock').then(function (lock) {
              if (lock) {
                return lock.reload().then(function () {
                  // Reload was successful, document still locked
                  return tryNext();
                }, function () {
                  // Reload failed, it's not locked anymore. Lock will be cleared by setupController which will
                  // create a new lock for this user
                  _this4.transitionToDocument(function () {
                    var documentController = _this4.controllerFor('documents/document');

                    if (documentController) {
                      documentController.set('rotatedDegrees', rotatedDegrees);
                    }
                  }, function () {
                    controller.set('index', currentIndex);
                  });
                });
              } else {
                // No lock found, it's not locked, let's go
                _this4.transitionToDocument(function () {
                  var documentController = _this4.controllerFor('documents/document');

                  if (documentController) {
                    documentController.set('rotatedDegrees', rotatedDegrees);
                  }
                }, function () {
                  controller.set('index', currentIndex);
                });
              }
            });
          });
        };

        if (index < lastDocumentIndex) {
          tryNext();
        } else if (index === lastDocumentIndex) {
          controller.loadMoreDocuments().then(tryNext, function () {
            // Reset index if unable to load more documents
            controller.set('index', currentIndex);
          });
        }
      },
      nextDocumentAction: function nextDocumentAction() {
        var _this5 = this;

        var controller = this.controllerFor('documents');
        var index = controller.get('index');
        var currentIndex = index;
        var documentsLength = controller.get('documents').length;
        var lastDocumentIndex = documentsLength - 1;
        var documentController = this.controllerFor('documents/document');
        var rotatedDegrees = documentController.get('rotatedDegrees');

        var doTransition = function doTransition() {
          controller.incrementProperty('index');

          _this5.transitionToDocument(function () {
            documentController.set('rotatedDegrees', rotatedDegrees);
          }, function () {
            controller.set('index', currentIndex);
          });
        };

        if (index < lastDocumentIndex) {
          doTransition();
        } else if (index === lastDocumentIndex) {
          controller.loadMoreDocuments().then(doTransition);
        }
      },
      prevDocumentAction: function prevDocumentAction() {
        var controller = this.controllerFor('documents');
        var currentIndex = controller.get('index');
        var documentController = this.controllerFor('documents/document');
        var rotatedDegrees = documentController.get('rotatedDegrees');

        if (!controller.get('index') <= 0) {
          controller.decrementProperty('index');
        }

        this.transitionToDocument(function () {
          documentController.set('rotatedDegrees', rotatedDegrees);
        }, function () {
          controller.set('index', currentIndex);
        });
      },
      nextDocumentEnterAction: function nextDocumentEnterAction() {
        var documentController = this.controllerFor('documents.document');
        var isDeleted = documentController.model.get('deleted');

        if (isDeleted) {
          M.toast({
            html: 'Det går inte att spara ett raderat dokument. Det måste först återställas',
            classes: 'red darken-1 rounded'
          });
          return;
        }

        documentController.send('saveToNextAction');
      },
      backToList: function backToList() {
        this.transitionTo('documents.index');
      },
      saveDocToListAction: function saveDocToListAction() {
        var documentController = this.controllerFor('documents.document');
        var isDeleted = documentController.model.get('deleted');

        if (isDeleted) {
          M.toast({
            html: 'Det går inte att spara ett raderat dokument. Det måste först återställas',
            classes: 'red darken-1 rounded'
          });
          return;
        }

        documentController.send('saveToListAction');
      },
      saveCopyOfDocAction: function saveCopyOfDocAction() {
        var documentController = this.controllerFor('documents.document');
        documentController.send('saveCopyAction');
      },
      gotoNextPageAction: function gotoNextPageAction() {
        var documentController = this.controllerFor('documents.document');
        documentController.send('nextPageAction');
      },
      gotoPrevPageAction: function gotoPrevPageAction() {
        var documentController = this.controllerFor('documents.document');
        documentController.send('prevPageAction');
      }
    }
  });

  _exports.default = _default;
});