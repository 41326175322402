define("frontend/models/attribute-value", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    avs_id: _emberData.default.attr('number'),
    doc: _emberData.default.belongsTo('document'),
    attr: _emberData.default.belongsTo('attribute'),
    valid: _emberData.default.attr('boolean'),
    value: _emberData.default.attr('string'),
    invalid: Ember.computed.not('valid'),
    hasNoDirtyAttributes: Ember.computed.not('hasDirtyAttributes'),
    invalidAndClean: Ember.computed.and('invalid', 'hasNoDirtyAttributes'),
    isClientCreatedChanged: Ember.computed('isNew', 'hasDirtyAttributes', 'value.length', function () {
      var isNew = this.get('isNew');
      var hasValue = this.get('value.length') > 0;
      var hasDirtyAttributes = this.hasDirtyAttributes; // If the attribute has not been saved and contains a value it is considered changed
      // New attributes which does not have a value should not be considered changed

      var isNewChanged = isNew && hasValue;

      if (isNewChanged) {
        return true;
      } // When the attribute has been saved once use the default hasDirtyAttributes computed property together with a
      // sanity check that there actually is a changed attribute. For some reason Ember Data seems to treat client
      // created attributes as new and pushes them into the store which makes them go into updated.uncommitted state.


      var hasChangedAttributes = Object.keys(this.changedAttributes()).length > 0;
      return !isNew && hasDirtyAttributes && hasChangedAttributes;
    })
  });

  _exports.default = _default;
});