define("frontend/services/bug-reporting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    version: 1,
    actions: [],
    data: {},
    errorReportContent: Ember.computed('version', function () {
      return JSON.stringify({
        data: this.get('data'),
        actions: this.get('actions')
      });
    }),
    stringify: function stringify() {
      return JSON.stringify({
        version: this.get('version'),
        actions: this.get('actions'),
        data: this.get('data')
      });
    }
  });

  _exports.default = _default;
});