define("frontend/models/message", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    title: _emberData.default.attr('string'),
    message: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('datetime'),
    read_at: _emberData.default.attr('datetime'),
    deleted_at: _emberData.default.attr('datetime'),
    user: _emberData.default.belongsTo('user'),
    isRead: Ember.computed('read_at', function () {
      return !!this.read_at;
    })
  });

  _exports.default = _default;
});