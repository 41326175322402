define("frontend/utils/get-file-created", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getFileCreated;

  function getFileCreated(file, index) {
    return new Promise(function (resolve) {
      //Step 2: Read the file using file reader
      var fileReader = new FileReader();
      var lastModified = (0, _moment.default)(file.lastModified);
      var result = {
        index: index,
        file: {
          raw: file.lastModified.toString(),
          moment: lastModified,
          date: lastModified.toISOString().substring(0, 10),
          time: lastModified.toISOString().substring(11, 19)
        }
      };

      var fallback = function fallback() {
        resolve(result);
      };

      try {
        fileReader.onload = function () {
          //Step 4:turn array buffer into typed array
          var typedarray = new Uint8Array(this.result); //Step 5:PDFJS should be able to read this

          var loadingTask = pdfjsLib.getDocument(typedarray);
          loadingTask.promise.then(function (pdf) {
            pdf.getMetadata().then(function (md) {
              var created = md.info.CreationDate;

              if (created) {
                var date = created.substring(2, 6) + '-' + created.substring(6, 8) + '-' + created.substring(8, 10);
                var time = created.substring(10, 12) + ':' + created.substring(12, 14) + ':' + created.substring(14, 16);
                result.pdf = {
                  raw: md.info.CreationDate,
                  moment: (0, _moment.default)(date + 'T' + time + 'Z'),
                  date: date,
                  time: time
                };
              }

              resolve(result);
            }, fallback);
          }, fallback);
        }; //Step 3:Read the file as ArrayBuffer


        fileReader.readAsArrayBuffer(file);
      } catch (e) {
        fallback();
      }
    });
  }
});