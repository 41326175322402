define("frontend/routes/application", ["exports", "frontend/utils/is-authenticated"], function (_exports, _isAuthenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    initializeUnload: Ember.on('init', function () {
      var route = this;
      jQuery(window).on('beforeunload', function () {
        var documentController = route.controllerFor('documents.document');

        if (documentController) {
          var model = documentController.get('model');

          if (model && model.get('isDirty')) {
            return false;
          }
        }
      });
    }),
    actions: {
      toRoute: function toRoute() {
        var _this$transitionTo;

        (_this$transitionTo = this.transitionTo).call.apply(_this$transitionTo, [this].concat(Array.prototype.slice.call(arguments)));

        return true;
      }
    }
  });

  _exports.default = _default;
});