define("frontend/components/application", ["exports", "jquery", "frontend/config/environment", "ember-data", "frontend/models/owner"], function (_exports, _jquery, _environment, _emberData, _owner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    bugReporting: Ember.inject.service(),
    _logout: function _logout() {
      _jquery.default.ajax(_environment.default.APP.apiHost + '/api/logout/', {
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true
      }).then(function () {
        window.location.href = '/';
      });
    },
    permissions: Ember.inject.service(),
    _fetchMessages: function _fetchMessages() {
      var _this = this;

      // Check if user has been authenticated every 2 seconds
      var refreshInterval = 2;

      if (this.isUserLoggedIn) {
        this.store.findAll('message').then(function (messages) {
          _this.set('messages', messages);

          _this.set('didLoadMessages', true);
        }); // Update every 30 seconds

        refreshInterval = 30;
      }

      Ember.run.later(this._fetchMessages.bind(this), refreshInterval * 1000);
    },
    _checkInactivity: function _checkInactivity() {
      // Check whether the user is logged in every 2 seconds
      if (!this.isUserLoggedIn) {
        Ember.run.later(this._checkInactivity.bind(this), 2 * 1000);
        return;
      }

      var lastActivity = sessionStorage.getItem('last_activity');
      if (!lastActivity) lastActivity = Date.now();
      var hours = Math.floor((Date.now() - lastActivity) / (60 * 60 * 1000));

      if (hours < 4) {
        // Run again in 10 minutes
        Ember.run.later(this._checkInactivity.bind(this), 600 * 1000);
        return;
      } // Remove the inactivity listener added on login


      (0, _jquery.default)(document).off('.sadokInactivityCheck');

      this._logout();
    },
    _checkForNewerVersion: function _checkForNewerVersion() {
      // Only refresh when the user is not logged in, usually during the next working day
      if (this.isUserLoggedIn) return;
      var that = this;
      var version = localStorage.getItem('sadok_version');

      _jquery.default.ajax(_environment.default.APP.apiHost + '/api/has-newer-version?version=' + (version ? version : ''), {
        type: 'GET',
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        complete: function complete(jqXHR) {
          if (!jqXHR.responseJSON) return;

          if (jqXHR.responseJSON.has_newer_version) {
            localStorage.setItem('sadok_version', jqXHR.responseJSON.version);
            window.location.href = '/';
          }

          Ember.run.later(that, that._checkForNewerVersion.bind(that), 30 * 1000);
        }
      });
    },
    fetchMessages: Ember.on('didInsertElement', function () {
      Ember.run.later(this, this._fetchMessages, 100);
    }),
    checkInactivity: Ember.on('didInsertElement', function () {
      Ember.run.later(this, this._checkInactivity, 100);
    }),
    checkForNewerVersion: Ember.on('didInsertElement', function () {
      if (!this.isUserLoggedIn) {
        var that = this;

        _jquery.default.ajax(_environment.default.APP.apiHost + '/api/version', {
          type: 'GET',
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: function success(response) {
            if (response.version) {
              localStorage.setItem('sadok_version', response.version);
              Ember.run.later(that, that._checkForNewerVersion.bind(that), 100);
            }
          }
        });
      }
    }),
    didLoadMessages: false,
    messages: [],
    unreadMessages: Ember.computed.filterBy('messages', 'isRead', false),
    reports: Ember.computed('user.owner_id', function () {
      var _this2 = this;

      var userPromise = this.get('user');

      if (!userPromise) {
        return new Promise(function (resolve, reject) {
          reject();
        });
      }

      return _emberData.default.PromiseArray.create({
        promise: userPromise.then(function (user) {
          return _this2.store.query('report', {
            owner_id: user.get('owner_id')
          });
        })
      });
    }),
    _fetchServerMessages: function _fetchServerMessages() {
      var _this3 = this;

      _jquery.default.ajax(_environment.default.APP.apiHost + '/api/server-messages/', {
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true
      }).then(function (response) {
        _this3.set('serverDisturbanceMessage', response && response.disturbance);

        _this3.set('serverInformationMessage', response && response.information); // Get height of messages to extend the document table so that no documents are cut off


        setTimeout(function () {
          var STANDARD_INCREASE = 120;
          var heightDisturbance = $('#server-disturbance-msg').length ? Math.ceil($('#server-disturbance-msg').height()) : 0;
          var heightInfo = $('#server-info-msg').length ? Math.ceil($('#server-info-msg').height()) : 0;
          var totalHeight = heightDisturbance + heightInfo;
          if (!totalHeight) return;
          if (!$('table.document-table').length) return;
          $('div.content-wrapper').css('height', 'calc(100vh - ' + (STANDARD_INCREASE + totalHeight + 10) + 'px)');
        }, 2000);
      }); // Run every 30 seconds


      Ember.run.later(this._fetchServerMessages.bind(this), 30 * 1000);
    },
    fetchServerMessages: Ember.on('didInsertElement', function () {
      Ember.run.later(this, this._fetchServerMessages, 100);
    }),
    serverDisturbanceMessage: '',
    serverInformationMessage: '',
    errorReportContent: Ember.observer('isUserLoggedIn', 'user.owner_id', 'user.id', function () {
      var user = {
        isUserLoggedIn: this.isUserLoggedIn,
        userId: this.get('user.id'),
        ownerId: this.get('user.owner_id')
      };
      this.set('bugReporting.data.user', user);
      this.incrementProperty('bugReporting.version');
    }),
    actions: {
      markMessageAsRead: function markMessageAsRead(message) {
        if (!message.read_at) {
          var now = moment().format('YYYY-MM-DD HH:mm:ss');
          message.set('read_at', now);
          message.save();
        }
      },
      markMessageAsUnread: function markMessageAsUnread(message) {
        if (message.read_at) {
          message.set('read_at', null);
          message.save();
        }
      },
      deleteMessage: function deleteMessage(message) {
        var now = moment().format('YYYY-MM-DD HH:mm:ss'); // Remove the message from frontend

        this.store.unloadRecord(message); // Remove the message from backend

        this.user.then(function (user) {
          _jquery.default.ajax(_environment.default.APP.apiHost + '/api/deleteMessage/', {
            type: 'POST',
            data: {
              messageId: message.id,
              userId: user.id,
              deletedAt: now
            }
          });
        });
      },
      changeOwnerId: function changeOwnerId(ownerId) {
        var _this4 = this;

        var that = this;
        this.user.then(function (user) {
          var shouldBeInStaging = user.get('stagingOwnerIds').indexOf(ownerId) !== -1;
          var shouldBeInProduction = user.get('productionOwnerIds').indexOf(ownerId) !== -1;
          var isInStaging = _environment.default.APP.isStaging;
          var isInProduction = !isInStaging;

          if (shouldBeInStaging && !isInStaging) {
            window.location.href = '/go-to-staging/' + ownerId;
            return;
          }

          if (shouldBeInProduction && !isInProduction) {
            window.location.href = '/go-to-production/' + ownerId;
            return;
          } // No need to query the backend for permissions if we already know them


          if (that.permissions.get('owner_id') !== ownerId || that.permissions.get('permission') === null) {
            $.ajax(_environment.default.APP.apiHost + '/api/users/get-permission?owner_id=' + ownerId, {
              type: 'GET',
              xhrFields: {
                withCredentials: true
              },
              crossDomain: true,
              success: function success(response) {
                that.permissions.set('permission', response.permission);
                that.permissions.set('owner_id', ownerId);
              },
              error: function error() {
                that.permissions.set('permission', null);
                that.permissions.set('owner_id', null);
              }
            });
          }

          user.set('owner_id', ownerId);
          user.save().then(function () {
            _this4.get('restart')();
          });
        });
      },
      logoutAction: function logoutAction() {
        _jquery.default.ajax(_environment.default.APP.apiHost + '/api/logout/', {
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true
        }).then(function () {
          (0, _jquery.default)(document).off('.sadokInactivityCheck');
          window.location.href = '/';
        });
      },
      switchDebug: function switchDebug() {
        this.toggleProperty('isDebugging');
      },
      sendBugReport: function sendBugReport() {
        var _this5 = this;

        _jquery.default.ajax(_environment.default.APP.apiHost + '/api/bugReport/', {
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          contentType: 'application/json',
          data: this.get('bugReporting').stringify()
        }).then(function (res) {
          _this5.set('lastBugReportId', res.id);
        });
      },
      resetLastBugReport: function resetLastBugReport() {
        this.set('lastBugReportId');
      }
    }
  });

  _exports.default = _default;
});