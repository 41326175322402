define("frontend/routes/documents/index", ["exports", "frontend/routes/route", "ember-keyboard-shortcuts", "jquery"], function (_exports, _route, _emberKeyboardShortcuts, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var $ = _jquery.default;

  var _default = _route.default.extend({
    model: function model() {
      var controller = this.controllerFor('documents');
      var documentsController = this.controllerFor('documents');
      documentsController.set('showDocBtns', false);
      return controller.get('documents');
    },
    setupController: function setupController(controller, model) {
      var documentsController = this.controllerFor('documents');

      if (!documentsController.get('showDocBtns')) {
        documentsController.set('rotateValue', 3);
        documentsController.set('zoomValue', 0);
      }

      this._super(controller, model);
    },
    keyboardShortcuts: {
      pagedown: {
        action: 'nextPageAction',
        global: true
      },
      pageup: {
        action: 'previousPageAction',
        global: true
      },
      enter: {
        action: 'selectCurrentEnter',
        global: true
      },
      esc: {
        action: 'selectCurrentEscape',
        global: true
      }
    },
    activate: function activate() {
      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
    },
    deactivate: function deactivate() {
      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
    },
    actions: {
      nextPageAction: function nextPageAction() {
        var $contentWrapper = $('.content-wrapper');
        var viewportHeight = $(window).height();
        var scrollTop = $contentWrapper.scrollTop() + viewportHeight * 0.6;
        $contentWrapper.animate({
          scrollTop: scrollTop
        }, 'fast');
      },
      previousPageAction: function previousPageAction() {
        var $contentWrapper = $('.content-wrapper');
        var viewportHeight = $(window).height();
        var scrollTop = $contentWrapper.scrollTop() - viewportHeight * 0.6;
        $contentWrapper.animate({
          scrollTop: scrollTop
        }, 'fast');
      },
      selectCurrentEnter: function selectCurrentEnter() {
        if (!(0, _jquery.default)('.modal.open').length) {
          if ((0, _jquery.default)('#search-input').is(':focus')) {
            (0, _jquery.default)('#quick-search').trigger('click');
          } else {
            this.send('transitionToDocument');
          }
        } else {
          (0, _jquery.default)('#advanced-button-search').trigger('click');
        }
      },
      selectCurrentEscape: function selectCurrentEscape() {
        if (!(0, _jquery.default)('.modal.open').length) {
          (0, _jquery.default)('#search-input').focus();
        } else {
          (0, _jquery.default)('#advanced-button-close').parents('.modal').modal('close');
        }
      }
    }
  });

  _exports.default = _default;
});