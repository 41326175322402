define("frontend/components/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    didStart: false,
    coverTrigger: false,
    constrainWidth: true,
    id: Ember.computed(function () {
      return Ember.guidFor(this);
    }),
    _start: function _start() {
      var $trigger = $('.dropdown-trigger[data-target="' + this.id + '"]');
      var config = {
        constrainWidth: this.constrainWidth,
        coverTrigger: this.coverTrigger
      };
      $trigger.dropdown(config);
    },
    start: Ember.on('didInsertElement', function () {
      if (this.condition) {
        this._start();

        this.didStart = true;
      }
    }),
    lateStart: Ember.observer('condition', function () {
      var _this = this;

      if (this.condition) {
        Ember.run.next(function () {
          _this._start();

          _this.didStart = true;
        });
      }
    })
  });

  _exports.default = _default;
});