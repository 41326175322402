define("frontend/models/attribute", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    attr_id: _emberData.default.attr('number'),
    attr_name: _emberData.default.attr('string'),
    attr_ingest_name: _emberData.default.attr('string'),
    attr_display_name: _emberData.default.attr('string'),
    attr_validation: _emberData.default.attr('string'),
    attr_group: _emberData.default.attr('string'),
    attr_descr: _emberData.default.attr('string'),
    attr_error: _emberData.default.attr('string'),
    attr_default: _emberData.default.attr('string'),
    attr_info: _emberData.default.attr('string'),
    attr_reject_doc_if_invalid: _emberData.default.attr('boolean'),
    isTransporterNumber: Ember.computed.equal('attr_name', 'Trpnr'),
    isAssignment: Ember.computed.equal('attr_name', 'Uppdrag')
  });

  _exports.default = _default;
});