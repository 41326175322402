define("frontend/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    username: attr('string'),
    password: attr('string'),
    name: attr('string'),
    settings: attr('object'),
    email: attr('string'),
    phone: attr('string'),
    owners: hasMany('owner'),
    stagingOwnerIds: attr('array'),
    productionOwnerIds: attr('array'),
    sortOwnersBy: ['owner_name'],
    sortedOwners: Ember.computed.sort('owners', 'sortOwnersBy'),
    hasMultipleOwners: Ember.computed.gt('owners.length', 1),
    owner: Ember.computed('owner_id', 'owners.@each.owner_id', function () {
      for (var index = 0; index < this.owners.length; index++) {
        if (this.owners.objectAt(index).owner_id === this.owner_id) {
          return this.owners.objectAt(index);
        }
      }
    }),
    owner_id: Ember.computed('settings.selectedOwnerId', {
      get: function get(key) {
        if (this.get('settings.selectedOwnerId')) {
          return this.get('settings.selectedOwnerId');
        } else {
          return this.get('owners.firstObject.id');
        }
      },
      set: function set(key, value) {
        this.set('settings.selectedOwnerId', value);
        return value;
      }
    })
  });

  _exports.default = _default;
});