define("frontend/components/datepicker", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'input',
    classNames: ['datepicker'],
    attributeBindings: ['name', 'id'],
    start: Ember.on('didInsertElement', function () {
      var i18n = {
        cancel: 'Avbryt',
        clear: 'Rensa',
        done: 'Ok',
        previousMonth: '‹',
        nextMonth: '›',
        months: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
        monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        weekdays: ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
        weekdaysShort: ['Sön', 'Mon', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör'],
        weekdaysAbbrev: ['Sö', 'Må', 'Ti', 'On', 'Ti', 'Fr', 'Lö']
      };
      this.instance = this.$().datepicker({
        autoClose: true,
        format: 'yyyy-mm-dd',
        firstDay: 1,
        i18n: i18n
      });
    }),
    valueDidChange: Ember.observer('value', function () {
      var lastModifiedDate = this.get('value');
      this.instance.datepicker('setDate', lastModifiedDate);
      this.instance.val(lastModifiedDate.toISOString().substring(0, 10));
    })
  });

  _exports.default = _default;
});