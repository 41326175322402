define("frontend/services/advanced-search-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Service.extend({
    searchData: undefined,
    hasSearchedOnce: false,
    set: function set(data) {
      this.searchData = data;
    },
    get: function get() {
      return this.searchData;
    },
    getOne: function getOne(id) {
      if (_typeof(this.searchData) === 'object') return this.searchData[id];
      return undefined;
    },
    clear: function clear() {
      this.searchData = undefined;
    },
    clearOnOtherSearch: function clearOnOtherSearch() {
      this.searchData = 'other-search-clear';
    },
    setSearchedOnce: function setSearchedOnce() {
      var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.hasSearchedOnce = value;
    },
    getSearchedOnce: function getSearchedOnce() {
      return this.hasSearchedOnce;
    }
  });

  _exports.default = _default;
});