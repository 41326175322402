define("frontend/components/document", ["exports", "ember-data", "jquery", "frontend/config/environment"], function (_exports, _emberData, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var $ = _jquery.default;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: '',
    focusedAttribute: '',
    imageLayout: 'portrait',
    hasWritePermission: false,
    pageNumber: null,
    history: [],
    showHistory: false,
    loadingHistory: false,
    isHistoryFeatureActive: _environment.default.appFeatures.historyButton,
    resetPageNumberOnDocumentChange: Ember.observer('document.id', function () {
      this.send('resetPageNumberAction');
    }),
    getWritePermissionOnDocumentChange: Ember.observer('document.id', function () {
      this.fetchWritePermissionStatus();
    }),
    fetchWritePermissionStatus: function fetchWritePermissionStatus() {
      var ownerId = this.get('document.owner_id');
      var trpnr = this.get('document.trpnr');

      if (!ownerId) {
        this.set('hasWritePermission', false);
        return;
      }

      if (!trpnr) {
        trpnr = 'missing';
      }

      var that = this;
      $.ajax(_environment.default.APP.apiHost + '/api/users/has-write-permission?owner_id=' + ownerId + '&trpnr=' + trpnr, {
        type: 'GET',
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        statusCode: {
          204: function _() {
            that.set('hasWritePermission', true);
          },
          400: function _() {
            that.set('hasWritePermission', false);
          },
          401: function _() {
            that.set('hasWritePermission', false);
          }
        }
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.fetchWritePermissionStatus();
      this.send('resetPageNumberAction');
    },
    measureImage: Ember.on('didInsertElement', function () {
      var component = this;
      $('#one img').on('load', function () {
        var ratio = this.width / this.height;

        if (ratio < 1) {
          component.set('imageLayout', 'portrait');
        } else {
          component.set('imageLayout', 'landscape');
        }
      });
    }),
    didChange: Ember.observer('document.file_name', function () {
      var _this = this;

      this.set('history', []);
      this.set('isLoading', true);
      this.set('isImageLoading', true);
      var img = new Image();
      var rotatedDegrees = this.get('rotatedDegrees');
      if (!rotatedDegrees) rotatedDegrees = 0;
      img.src = this.get('document.url') + '&rotate=' + rotatedDegrees + '&page=1';

      img.onload = img.onerror = function () {
        _this.set('isLoading', false);

        _this.set('isImageLoading', false);
      };
    }),
    multipleColumns: Ember.computed.gt('attributeValues.length', 10),
    columnSplitIndex: Ember.computed('attributeValues.length', 'wideAttributes.length', function () {
      var narrowAttributeCount = this.get('attributeValues.length') - this.get('wideAttributes.length');
      return Math.floor(narrowAttributeCount / 2) - 1;
    }),
    wideAttributes: Ember.computed.filterBy('ownerAttributes', 'attr_show_wide', true),
    attributeValues: Ember.computed('document.attr_values.@each.id', 'document.attr_values.@each.attr.id', 'ownerAttributes.@each.attr_web_sort_order', function () {
      var _this2 = this;

      var store = this.get('store');
      var document = this.get('document');
      var promise = new Ember.RSVP.Promise(function (resolve) {
        var attributeValuesPromise = _this2.get('document.attr_values');

        var ownerAttributesPromise = _this2.get('ownerAttributes');

        Ember.RSVP.all([attributeValuesPromise, ownerAttributesPromise]).then(function (result) {
          var _result = _slicedToArray(result, 2),
              attributeValues = _result[0],
              ownerAttributes = _result[1]; // Create new attributes for those that are missing from the document


          ownerAttributes.forEach(function (ownerAttribute) {
            if (!attributeValues.findBy('attr.id', ownerAttribute.get('attr.id'))) {
              // Create attribute with a temporary ID only to have any reference back to it to present validation errors
              attributeValues.pushObject(store.createRecord('attribute-value', {
                id: Ember.guidFor(Math.random()),
                document: document,
                attr: ownerAttribute.get('attr'),
                valid: true
              }));
            }
          }); // Only display attributes which have a owner attribute

          attributeValues = attributeValues.filter(function (attributeValue) {
            return ownerAttributes.findBy('attr.id', attributeValue.get('attr.id'));
          });
          var sorted = attributeValues.toArray().sort(function (a, b) {
            var aOwnerAttribute = ownerAttributes.findBy('attr.id', a.get('attr.id'));
            var bOwnerAttribute = ownerAttributes.findBy('attr.id', b.get('attr.id'));

            if (!aOwnerAttribute || !bOwnerAttribute) {
              throw new Error('Unknown attribute');
            }

            return aOwnerAttribute.get('attr_web_sort_order') - bOwnerAttribute.get('attr_web_sort_order');
          });
          resolve(sorted);
        });
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    initNumpadHandler: Ember.on('didInsertElement', function () {
      $(document).on('keydown', this.numpadHandler);
    }),
    removeNumpadHandler: Ember.on('willDestroyElement', function () {
      $(document).off('keydown', this.numpadHandler);
    }),
    initializePanning: Ember.on('didInsertElement', function () {
      var slider = document.getElementById("one");
      var isDown = false;
      var startX;
      var startY;
      var scrollLeft;
      var scrollTop;
      slider.addEventListener("mousedown", function (e) {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        startY = e.pageY - slider.offsetTop;
        scrollLeft = slider.scrollLeft;
        scrollTop = slider.scrollTop;
      });
      slider.addEventListener("mouseleave", function () {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", function () {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", function (e) {
        if (!isDown) return;
        e.preventDefault();
        var x = e.pageX - slider.offsetLeft;
        var y = e.pageY - slider.offsetTop;
        var walkX = x - startX;
        var walkY = y - startY;
        slider.scrollLeft = scrollLeft - walkX;
        slider.scrollTop = scrollTop - walkY;
      });
    }),
    numpadHandler: function numpadHandler(e) {
      var NUMPAD_PLUS = 107;
      var NUMPAD_MINUS = 109;

      if (e.keyCode === NUMPAD_PLUS || e.keyCode === NUMPAD_MINUS) {
        e.preventDefault();
        var $enabledEditableAttributes = $('.document-attributes .attribute-value').find('input:not(:disabled), select:not(:disabled)').parents('.attribute-value'); // parents() return elements in reverse order

        var $focusedAttribute = $enabledEditableAttributes.find('input:focus, select:focus');
        var attributeCount = $enabledEditableAttributes.length;
        var newIndex = -1;
        var $newFocusedAttribute; // find index of focused attribute

        var attributeIndex = 0;

        if ($focusedAttribute.length > 0) {
          $.each($enabledEditableAttributes, function (index, value) {
            if ($(value).attr('id') === $focusedAttribute.parents('.attribute-value').attr('id')) {
              attributeIndex = attributeCount - index - 1; // reverse index

              return false;
            }
          });
        } // Numpad+ used with no focused attribute


        if ($focusedAttribute.length === 0 && e.keyCode === NUMPAD_PLUS) {
          newIndex = 0;
        } // Numpad- used with no focused attribute
        else if ($focusedAttribute.length === 0 && e.keyCode === NUMPAD_MINUS) {
            newIndex = attributeCount - 1;
          } // Numpad+ used with focused attribute
          else if (e.keyCode === NUMPAD_PLUS) {
              newIndex = attributeIndex + 1;

              if (newIndex >= attributeCount) {
                newIndex = 0;
              }
            } // Numpad- used with focused attribute
            else if (e.keyCode === NUMPAD_MINUS) {
                newIndex = attributeIndex - 1;

                if (newIndex < 0) {
                  newIndex = attributeCount - 1;
                }
              }

        if (newIndex >= 0) {
          $newFocusedAttribute = $($enabledEditableAttributes[attributeCount - newIndex - 1]); // reverse index

          $newFocusedAttribute.find('input, select').focus();
        }
      }
    },
    actions: {
      hideHistoryAction: function hideHistoryAction() {
        this.set('showHistory', false);
      },
      viewHistoryAction: function viewHistoryAction() {
        var _this3 = this;

        if (this.get('history.length')) {
          this.set('showHistory', true);
          return;
        }

        this.set('loadingHistory', true);
        var that = this;
        this.get('user').then(function (user) {
          var currentOwnerId = user.get('owner_id');
          $.ajax(_environment.default.APP.apiHost + '/api/documents/view-history/' + _this3.get('document.id') + '?owner_id=' + currentOwnerId, {
            type: 'GET',
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            success: function success(response) {
              that.set('history', response);
              that.set('showHistory', true);
            },
            error: function error() {
              M.toast({
                html: 'Något gick fel',
                classes: 'red darken-1 rounded'
              });
            },
            complete: function complete() {
              that.set('loadingHistory', false);
            }
          });
        });
      },
      resetPageNumberAction: function resetPageNumberAction() {
        this.resetPageNumberAction();
      },
      saveCopyAction: function saveCopyAction() {
        this.saveCopyAction();
      },
      saveToListAction: function saveToListAction() {
        this.saveToListAction();
      },
      saveToNextAction: function saveToNextAction() {
        this.saveToNextAction();
      },
      deleteDocumentAction: function deleteDocumentAction() {
        this.deleteDocumentAction();
      },
      restoreDocumentAction: function restoreDocumentAction() {
        this.restoreDocumentAction();
      }
    }
  });

  _exports.default = _default;
});