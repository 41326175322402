define("frontend/components/settlement-modal", ["exports", "ember-data", "frontend/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isLoading: true,
    preFetchedData: [],
    hasPreFetchedData: Ember.computed.notEmpty('preFetchedData'),
    preFetchedHeaders: Ember.computed('preFetchedData.firstObject', function () {
      var value = this.get('preFetchedData.firstObject');

      if (value) {
        return Object.keys(value);
      }
    }),
    headers: Ember.computed('data.firstObject', function () {
      var value = this.get('data.firstObject');

      if (value) {
        return Object.keys(value);
      }
    }),
    data: Ember.computed('owner.id', 'document.id', 'fsnr', function () {
      var _this = this;

      if (!this.get('owner.id') || !this.get('document.id') || !this.get('fsnr') && !this.get('sndnr')) {
        this.set('isLoading', false);
        return;
      }

      var promise = new Promise(function (resolve) {
        var url = _environment.default.APP.apiHost + '/api/settlement/' + _this.get('document.id') + '?owner_id=' + _this.get('owner.id');

        if (_this.get('fsnr')) {
          url += '&fsnr=' + _this.get('fsnr');
        }

        if (_this.get('sndnr')) {
          url += '&sndnr=' + _this.get('sndnr');
        }

        jQuery.ajax(url, {
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true
        }).then(function (response) {
          _this.set('isLoading', false);

          resolve(response);
        });
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    initialize: Ember.on('didInsertElement', function () {
      var _this2 = this;

      $('.modal-settlement').modal({
        onCloseEnd: function onCloseEnd() {
          _this2.set('open', false);
        },
        startingTop: '0',
        // Starting top style attribute
        endingTop: '5%' // Ending top style attribute

      });
      $('#modal-settlement').modal('open');
    })
  });

  _exports.default = _default;
});