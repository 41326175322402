define("frontend/helpers/inc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function inc(index) {
    index++;
    return index;
  });

  _exports.default = _default;
});