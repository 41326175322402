define("frontend/components/search-form", ["exports", "frontend/models/attribute"], function (_exports, _attribute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    permissions: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    advancedSearchData: Ember.inject.service(),
    errors: [],
    searchDeleted: false,
    init: function init() {
      this._super.apply(this, arguments);

      var userInput = this.advancedSearchData.get();

      if (_typeof(userInput) === 'object') {
        this.set('documentAttributesQuery', userInput);

        for (var _i = 0, _Object$keys = Object.keys(userInput); _i < _Object$keys.length; _i++) {
          var attributeId = _Object$keys[_i];
          this.set(attributeId, userInput[attributeId]);
        }
      }
    },
    actions: {
      search: function search() {
        var _this = this;

        var attributeIds = this.ownerAttributes.mapBy('attr.id');
        var attributeValues = {};
        var hasInvalidWildcardPlacement = false;
        var quick = 0;
        var slow = 0;
        this.set('errors', []);

        if (this.advancedSearchData.get() !== 'other-search-clear' || !this.advancedSearchData.getSearchedOnce()) {
          this.advancedSearchData.setSearchedOnce();
          this.advancedSearchData.clear();
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = attributeIds[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var attributeId = _step.value;
              var value = this.get(attributeId);

              if (value) {
                attributeValues[attributeId] = value;

                if (this.ownerAttributes.findBy('attr.id', attributeId).get('attr_web_quicksearch')) {
                  quick++;
                } else {
                  slow++;
                }

                if (false && value && value.indexOf('*') !== -1 && value.indexOf('*') < 1) {
                  // 32875 allow any wildcard placement
                  hasInvalidWildcardPlacement = true;
                }
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        } else {
          // Clear locally stored search data
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = attributeIds[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var _attributeId = _step2.value;
              this.set(_attributeId);
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          this.set('scanDate');
          this.set('documentAttributesQuery'); // Clear search data stored in service

          this.advancedSearchData.clear();
        }

        if (this.get('scanDate')) {
          quick++;
          attributeValues['scanDate'] = this.get('scanDate');
        }

        if (hasInvalidWildcardPlacement) {
          this.get('errors').pushObject('Du måste ha minst ett tecken före wildcard!');
        }

        if (quick === 0 && slow < 2) {
          this.get('errors').pushObject('Du måste ange minst ett sökvillkor märkt med (q) eller minst två märkta med (s)!');
        }

        if (this.errors.length) {
          return;
        }

        this.$().parents('.modal').modal('close');
        this.documents.removeObjects(this.documents);
        this.set('isLoading', true);
        this.set('documentFilter', '');
        this.set('isLoadingMoreStopped', false);
        this.set('index', 0);
        this.set('offset', 1);

        if (this.get('searchDeleted')) {
          attributeValues['showDeleted'] = '1';
        } else {
          attributeValues['showDeleted'] = '0';
        }

        this.advancedSearchData.set(attributeValues);
        this.updateDocumentCount();
        this.updateDownloadLink();
        this.get('user').then(function (user) {
          var query = {
            attributes: _this.advancedSearchData.get(),
            owner_id: user.get('owner_id')
          };

          _this.set('documentAttributesQuery', _this.advancedSearchData.get());

          _this.store.query('document', query).then(function (documents) {
            _this.documents.pushObjects(documents.toArray());

            _this.updateDocumentCount(query);

            _this.updateDownloadLink(query);

            _this.set('isLoading', false);

            if (documents.length === 1) {
              _this.router.transitionTo('documents.document', documents.firstObject.id);
            } else {
              _this.router.transitionTo('documents');
            }
          }, function (response) {
            var errors = {};

            if (response.errors && typeof response.errors.forEach === 'function') {
              response.errors.forEach(function (error) {
                var key = error.source.pointer.replace(/\/data\/attributes\//, '');

                if (typeof errors[key] === 'undefined') {
                  errors[key] = [];
                }

                errors[key].push(error.detail);
              });

              _this.set('errors', errors);

              _this.set('isLoading', false);

              $('#modal1').modal('open');
            }
          });
        });
      },
      searchDeletedAction: function searchDeletedAction(currentValue) {
        // Toggle between true and false
        this.set('searchDeleted', !currentValue);
      },
      closeForm: function closeForm() {
        $('#modal1').modal('close');
      }
    }
  });

  _exports.default = _default;
});