define("frontend/helpers/datetime", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function datetime(params, namedArgs) {
    var value = params[0];

    if (!value) {
      return '';
    }

    var dateFormat = namedArgs.dateFormat || 'YYYY-MM-DD';
    var timeFormat = namedArgs.timeFormat || 'HH:mm';
    return moment(value).format(dateFormat + ' ' + timeFormat);
  });

  _exports.default = _default;
});