define("frontend/components/settlement-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isEveryItemNumeric: Ember.computed('data.@each', 'key', function () {
      var _this = this;

      var isEveryItemNumeric = true;
      this.data.forEach(function (item) {
        if (!_this.isNumeric(item[_this.key])) {
          isEveryItemNumeric = false;
        }
      });
      return isEveryItemNumeric;
    }),
    isNumeric: function isNumeric(value) {
      // Only accept parsable numbers
      if (isNaN(parseFloat(value))) {
        return false;
      } // Require a period to separate other types of numbers


      return value.match(/^-?\d+\.\d+$/);
    },
    sum: Ember.computed('data.@each', 'key', function () {
      var _this2 = this;

      var sum = 0;
      this.data.forEach(function (item) {
        sum += Math.round(parseFloat(item[_this2.key]) * 100);
      });
      return sum / 100;
    })
  });

  _exports.default = _default;
});