define("frontend/components/user-profile", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    passwordErrors: [],
    store: Ember.inject.service(),
    actions: {
      changePassword: function changePassword() {
        var newPassword = this.get('newPassword') || '';
        var currentPassword = this.get('currentPassword') || '';
        var repeatPassword = this.get('repeatPassword') || '';
        this.set('isNewPasswordInvalid', newPassword.length < 10);
        this.set('isRepeatPasswordInvalid', newPassword !== repeatPassword);
        this.set('isCurrentPasswordInvalid', currentPassword.length === 0);

        if (this.isNewPasswordInvalid || this.isRepeatPasswordInvalid || this.isCurrentPasswordInvalid) {
          return;
        }

        var host = _environment.default.APP.apiHost;
        var value = {
          reset: {
            currentPassword: currentPassword,
            password: newPassword,
            user: this.user.id
          }
        };
        var that = this;
        jQuery.ajax(host + '/api/reset/set/', {
          type: 'POST',
          contentType: "application/json",
          data: JSON.stringify(value),
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: function success() {
            M.toast({
              html: 'Ditt lösenord har uppdaterats',
              classes: 'blue darken-1 rounded'
            });
            that.set('isCurrentPasswordInvalid', false);
            that.set('newPassword');
            that.set('currentPassword');
            that.set('repeatPassword');
          },
          error: function error(jqXHR) {
            var response = jqXHR.responseJSON;

            if (response && !response.isValid) {
              that.set('passwordErrors', response.errors);
              return;
            }

            that.set('isCurrentPasswordInvalid', true);
          }
        });
      },
      save: function save() {
        this.user.save().then(function () {
          M.toast({
            html: 'Inställningarna har sparats',
            classes: 'blue darken-1 rounded'
          });
        }, function () {
          M.toast({
            html: 'Inställningarna kunde inte sparas!',
            classes: 'red darken-1 rounded'
          });
        });
      },
      rollback: function rollback() {
        this.user.rollbackAttributes();
      },
      rollbackPassword: function rollbackPassword() {
        this.set('newPassword');
        this.set('currentPassword');
        this.set('repeatPassword');
        this.set('isNewPasswordInvalid');
        this.set('isRepeatPasswordInvalid');
        this.set('isCurrentPasswordInvalid');
      }
    }
  });

  _exports.default = _default;
});