define("frontend/templates/components/quick-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1GNt+eHz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row valign-wrapper\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col tooltipped\"],[10,\"data-tooltip\",\"Snabbsökning [Esc]\"],[8],[1,[28,\"input\",null,[[\"id\",\"value\"],[\"search-input\",[24,[\"query\"]]]]],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"id\",\"quick-search\"],[12,\"class\",\"waves-effect waves-light btn tooltipped searchcontrol\"],[12,\"data-tooltip\",\"Utför sökning [Enter]\"],[3,\"action\",[[23,0,[]],\"search\"]],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"material-icons\"],[8],[0,\"search\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"a\",true],[10,\"class\",\"waves-effect waves-light btn modal-trigger tooltipped searchcontrol extended-search\"],[10,\"href\",\"#modal1\"],[10,\"data-tooltip\",\"Visa avancerad sökning\"],[8],[7,\"i\",true],[10,\"class\",\"material-icons\"],[8],[0,\"arrow_drop_down\"],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"settlementModalOpen\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"settlement-modal\",null,[[\"preFetchedData\",\"open\",\"fnsr\"],[[24,[\"settlementData\"]],[24,[\"settlementModalOpen\"]],[24,[\"settlementFsnr\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/quick-search.hbs"
    }
  });

  _exports.default = _default;
});