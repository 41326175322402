define("frontend/components/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    _options: Ember.computed('validator', 'options', 'valid', function () {
      var _this = this;

      var options = [];
      var values = [];

      if (this.options) {
        options = this.options.map(function (item) {
          return {
            value: item,
            label: item,
            disabled: false
          };
        });
        values = this.options;
      } else if (this.validator) {
        var valString = this.validator;
        var explodedString = valString.split(';');
        var BreakException = {};

        try {
          explodedString.forEach(function (attrString) {
            if (attrString.includes('set(')) {
              valString = attrString;
              throw BreakException;
            }
          });
        } catch (e) {
          if (e !== BreakException) throw e;
        }

        var valStringArrayWithEndParent = valString.split('(');
        var valStringArrWithoutParent = valStringArrayWithEndParent[1].split(')');
        var valStringArrWithComma = valStringArrWithoutParent[0];
        values = valStringArrWithComma.split(',');
        options = values.map(function (val) {
          var label = val;
          var disabled = false;

          if (_this.get('attr.attr_name') === 'Avrstat') {
            var data = _this.get('avrstats').findBy('value', val);

            label = data.name;
            disabled = data.disabled;
          }

          return {
            value: val,
            label: label,
            disabled: disabled
          };
        });
      }

      var visibleValue = this.setValue || '';

      if (values.indexOf(visibleValue) === -1) {
        var data = this.get('avrstats').findBy('value', visibleValue);
        var disabled = data && data.disabled;
        options.push({
          value: this.setValue,
          label: visibleValue + ' (felaktigt)',
          disabled: disabled
        });
      }

      return options;
    }),
    avrstats: [{
      name: 'Godkänns',
      value: '1',
      changeableTo: []
    }, {
      name: 'Ej avräknad',
      value: '2',
      changeableTo: ['Avräknas ej', 'Avräknad man']
    }, {
      name: 'Felavräknad',
      value: '3',
      changeableTo: ['Avräknas ej', 'Avräknad man']
    }, {
      name: 'Övrigt',
      value: '4',
      changeableTo: []
    }, {
      name: 'Avräknas',
      value: '5',
      changeableTo: ['Avräknas ej']
    }, {
      name: 'Faktureras',
      value: '6',
      changeableTo: []
    }, {
      name: 'Avräknas ej',
      value: '7',
      changeableTo: ['Avräknas']
    }, {
      name: 'Avräknad aut',
      value: '8',
      changeableTo: []
    }, {
      name: 'Avräknad man',
      value: '9',
      changeableTo: ['Avräknas']
    }],
    finalOptions: Ember.computed('setValue', '_options.@each', function () {
      var attributeName = this.get('attr.attr_name');

      if (attributeName !== 'Avrstat') {
        return this.get('_options');
      }

      var avrstats = this.get('avrstats');
      var originalValue = this.get('originalValue');

      if (typeof originalValue === 'undefined' || originalValue === null) {
        originalValue = this.get('setValue');
        this.set('originalValue', originalValue);
      }

      var currentAvrstat = avrstats.find(function (avrstat) {
        return avrstat.value === originalValue;
      });
      return this.get('_options').map(function (option) {
        var isLegal = currentAvrstat.changeableTo.includes(option.label) || originalValue === option.value;
        Ember.set(option, 'disabled', !isLegal);
        return option;
      });
    }),
    actions: {
      changeValue: function changeValue(value) {
        var attributeName = this.get('attr.attr_name');

        if (attributeName === 'Avrstat') {
          var avrstats = this.get('avrstats');
          var originalValue = this.get('originalValue');
          var chosenAvrstat = avrstats.find(function (avrstat) {
            return avrstat.value === value;
          });
          var originalAvrstat = avrstats.find(function (avrstat) {
            return avrstat.value === originalValue;
          }); // Exit early if user has selected an illegal avrstat

          if (!originalAvrstat.changeableTo.includes(chosenAvrstat.name)) {
            return;
          }
        }

        this.set('setValue', value);
      }
    }
  });

  _exports.default = _default;
});