define("frontend/helpers/new-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function newRow(params) {
    var index = params[0];

    if (index % 2 === 1) {
      return Ember.String.htmlSafe('<div class="row"></div>');
    }

    return '';
  });

  _exports.default = _default;
});