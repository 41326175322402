define("frontend/templates/documents-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MD1srlQ9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"center-align\"],[8],[0,\"\\n        \"],[7,\"h2\",true],[8],[0,\"Hämtar dokument...\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"progress\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"indeterminate\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/documents-loading.hbs"
    }
  });

  _exports.default = _default;
});