define("frontend/components/attribute-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isCheckbox: Ember.computed.equal('ownerAttribute.attr.attr_validation', 'set(,J)'),
    isSelect: Ember.computed('ownerAttribute.attr.attr_validation', 'ownerAttribute.owner.hasFewTransporterNumbers', 'ownerAttribute.attr.isTransporterNumber', 'ownerAttribute.attr.isAssignment', function () {
      if (this.get('ownerAttribute.attr.isTransporterNumber') && this.get('ownerAttribute.owner.hasFewTransporterNumbers')) {
        return true;
      }

      if (this.get('ownerAttribute.attr.isAssignment')) {
        return true;
      }

      var validation = this.get('ownerAttribute.attr.attr_validation');

      if (!validation) {
        return false;
      }

      return validation.match(/set\(/) && validation !== 'set(,J)';
    }),
    options: Ember.computed('isSelect', 'ownerAttribute.attr.attr_validation', 'ownerAttribute.attr.attr_name', 'ownerAttribute.attr.isTransporterNumber', 'ownerAttribute.owner.hasFewTransporterNumbers', function () {
      var _this = this;

      var options = [];
      var attributeName = this.get('ownerAttribute.attr.attr_name');
      var map = {
        '1': 'Godkänns',
        '2': 'Ej avräknad',
        '3': 'Felavräknad',
        '4': 'Övrigt',
        '5': 'Avräknas',
        '6': 'Faktureras',
        '7': 'Avräknas ej',
        '8': 'Avräknad aut',
        '9': 'Avräknad man',
        '10': '10'
      };

      if (!this.isSelect) {
        return options;
      }

      var isTransporterNumber = this.get('ownerAttribute.attr.isTransporterNumber') && this.get('ownerAttribute.owner.hasFewTransporterNumbers');

      if (isTransporterNumber) {
        var trpnrs = this.get('ownerAttribute.owner.trpnrs');

        if (trpnrs.length > 1) {
          options.push({
            value: '',
            label: ''
          });
        }

        trpnrs.forEach(function (item) {
          options.push({
            value: item,
            label: item,
            selected: trpnrs.length === 1
          });
        });
        var firstValue = options.length ? options[0].value : null;

        if (firstValue && options.length === 1 && this.value !== firstValue) {
          Ember.run.next(function () {
            _this.set('value', firstValue);
          });
        }

        return options;
      }

      var validation = this.get('ownerAttribute.attr.attr_validation');

      if (!validation) {
        return options;
      }

      validation.split(/;/).forEach(function (validator) {
        if (validator.match(/set\(/)) {
          var optionsString = validator.substring(4, validator.length - 1);

          if (optionsString.substring(0, 1) !== ',') {
            optionsString = ',' + optionsString;
          }

          optionsString.split(/,/).forEach(function (option) {
            var value = option || '';
            var label = option || '';

            if (attributeName === 'Avrstat') {
              label = map[label] || '';
            }

            options.push({
              value: value,
              label: label
            });
          });
        }
      });
      return options;
    }),
    checkedValue: Ember.computed('ownerAttribute.attr.attr_validation', function () {
      var validation = this.get('ownerAttribute.attr.attr_validation');

      if (!validation) {
        return;
      }

      validation = validation.replace(/set\(/, '');
      validation = validation.replace(/\)/, '');
      var parts = validation.split(',');
      return parts[1]; // First item is for "false"
    }),
    uncheckedValue: Ember.computed('ownerAttribute.attr.attr_validation', function () {
      var validation = this.get('ownerAttribute.attr.attr_validation');

      if (!validation) {
        return;
      }

      validation = validation.replace(/set\(/, '');
      validation = validation.replace(/\)/, '');
      var parts = validation.split(',');
      return parts[0]; // First item is for "false"
    }),
    isChecked: Ember.computed('ownerAttribute.value', 'checkedValue', 'uncheckedValue', {
      'set': function set(key, value) {
        var attributeValue = value ? this.get('checkedValue') : this.get('uncheckedValue');
        this.set('value', attributeValue);
        return value;
      },
      'get': function get() {
        return this.get('value') === this.get('checkedValue');
      }
    }),
    actions: {
      changeValue: function changeValue(value) {
        this.set('value', value);
      },
      changeCheckbox: function changeCheckbox(value) {
        this.set('isChecked', value);
      }
    }
  });

  _exports.default = _default;
});