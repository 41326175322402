define("frontend/serializers/document", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      attr_values: {
        serialize: 'records',
        deserialize: 'records'
      }
    },
    // https://github.com/emberjs/data/issues/4273
    extractErrors: function extractErrors(store, typeClass, payload, id) {
      var _this = this;

      payload = this._super.apply(this, arguments);
      var record = store.peekRecord(typeClass.modelName, id);
      var errorKeys = Object.keys(payload);
      typeClass.eachRelationship(function (name, descriptor) {
        var key = _this.keyForAttribute(name, 'deserialize');

        if (descriptor.kind === 'belongsTo') {
          var relatedModel = record.get(name);
          var errorRegex = new RegExp("^".concat(key, "/attributes/(.*)"));
          errorKeys.forEach(function (errorKey) {
            var match = errorKey.match(errorRegex);

            if (match) {
              if (!relatedModel.isDirty) {
                relatedModel.send('becomeDirty');
              }

              relatedModel.get('errors').add(Ember.String.camelize(match[1]), payload[match[0]]);
              relatedModel.set('isError', true);
              delete payload[match[0]];
            }
          });
        } else if (descriptor.kind === 'hasMany') {
          var _errorRegex = new RegExp("^".concat(key, "/(.*)/attributes/(.*)"));

          errorKeys.forEach(function (errorKey) {
            var match = errorKey.match(_errorRegex);

            if (match) {
              var _relatedModel = store.peekRecord(descriptor.type, match[1]);

              if (_relatedModel) {
                if (!_relatedModel.isDirty) {
                  _relatedModel.send('becomeDirty');
                }

                _relatedModel.get('errors').add(Ember.String.camelize(match[2]), payload[match[0]]);

                _relatedModel.set('isError', true);

                delete payload[match[0]];
              }
            }
          });
        }
      });
      return payload;
    }
  });

  _exports.default = _default;
});