define("frontend/components/document-filter", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    advancedSearchData: Ember.inject.service(),
    availableFilters: Ember.computed('user.owner_id', function () {
      var _this = this;

      var userPromise = this.get('user');

      if (!userPromise) {
        return new Promise(function (resolve, reject) {
          reject();
        });
      }

      return _emberData.default.PromiseArray.create({
        promise: userPromise.then(function (user) {
          return _this.store.query('filter', {
            owner_id: user.get('owner_id')
          });
        })
      });
    }),
    defaultFilter: Ember.on('didInsertElement', function () {
      var _this2 = this;

      // Reset and apply default filter when re-rendering with another owner
      if (this.filterOwnerId && this.filterOwnerId !== this.get('user.owner_id')) {
        this.set('documentQuery', '');
        this.set('documentAttributesQuery', '');
        this.set('documentFilter', '');
        this.set('filterOwnerId', this.get('user.owner_id'));
      }

      if (!this.get('documentQuery') && !this.get('documentAttributesQuery')) {
        this.get('availableFilters').then(function (filters) {
          var firstFilter = filters.get('firstObject');

          if (firstFilter && !_this2.get('documentFilter')) {
            _this2.send('filterAction', firstFilter);
          }
        });
      }
    }),
    actions: {
      filterAction: function filterAction(filter) {
        var _this3 = this;

        $('#search-input').val('');
        this.documents.removeObjects(this.documents);
        this.set('isLoading', true);
        document.getElementById('advanced-search-form').reset();
        this.advancedSearchData.clearOnOtherSearch();
        this.updateDocumentCount();
        this.updateDownloadLink();
        var rev = this.incrementProperty('queryRevision');
        this.get('user').then(function (user) {
          if (rev !== _this3.get('queryRevision')) {
            return;
          }

          var query = {
            owner_id: user.get('owner_id')
          };
          query.filter = filter.id;

          _this3.set('activatedFilter', filter.filter_display_name);

          _this3.set('documentFilter', filter);

          _this3.set('documentQuery', '');

          _this3.set('isLoadingMoreStopped', false);

          _this3.set('offset', 1);

          _this3.set('filterOwnerId', user.get('owner_id'));

          _this3.store.query('document', query).then(function (documents) {
            if (rev !== _this3.get('queryRevision')) {
              return;
            }

            _this3.documents.clear();

            _this3.documents.pushObjects(documents.toArray());

            _this3.updateDocumentCount(query);

            _this3.updateDownloadLink(query);

            _this3.set('isLoading', false);
          });
        });
      }
    }
  });

  _exports.default = _default;
});