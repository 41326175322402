define("frontend/components/login-form", ["exports", "jquery", "frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    show2fa: false,
    userEmail: '',
    twoFAcode: '',
    maySendCode: true,
    router: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      _jquery.default.ajax(_environment.default.APP.apiHost + '/api/welcome-message/', {
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true
      }).then(function (response) {
        if (response && response.message) {
          _this.set('welcomeMessage', response.message);
        }
      });
    },
    actions: {
      loginAction: function loginAction() {
        var host = _environment.default.APP.apiHost;
        var value = {
          auth: {
            username: this.username,
            password: this.password
          }
        };
        var that = this;

        _jquery.default.ajax(host + '/api/auth/', {
          type: 'POST',
          contentType: "application/json",
          data: JSON.stringify(value),
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: function success(response) {
            if (response && response.redirect) {
              window.location.href = response.redirect;
              return;
            }

            if (response && response.twoFA) {
              that.set('show2fa', true);
              that.set('userEmail', response.email);
              return;
            } // Keeps track of inactivity


            var needsToWait = false;
            (0, _jquery.default)(document).on('mousemove.sadokInactivityCheck keydown.sadokInactivityCheck', null, null, function () {
              if (needsToWait) return;
              needsToWait = true;
              sessionStorage.setItem('last_activity', Date.now().toString());
              setTimeout(function () {
                return needsToWait = false;
              }, 30 * 1000); // wait 30 seconds for the next event
            });
            that.set('isUserLoggedIn', true);
            that.get('router').transitionTo('documents');
          },
          error: function error() {
            M.toast({
              html: 'Inloggningen Misslyckades',
              classes: 'red darken-1 rounded'
            });
          }
        });
      },
      verify2fa: function verify2fa() {
        var that = this;

        _jquery.default.ajax(_environment.default.APP.apiHost + '/api/auth/2fa', {
          type: 'POST',
          contentType: "application/json",
          data: JSON.stringify({
            code: that.twoFAcode,
            email: that.userEmail
          }),
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: function success() {
            that.set('isUserLoggedIn', true);
            that.get('router').transitionTo('documents');
          },
          statusCode: {
            401: function _() {
              M.toast({
                html: 'Koden du angav var inkorrekt',
                classes: 'red darken-1 rounded'
              });
            },
            403: function _() {
              M.toast({
                html: 'Koden har gått ut',
                classes: 'red darken-1 rounded'
              });
            }
          },
          error: function error(jqXHR) {
            switch (jqXHR.status) {
              case 401:
              case 403:
                return;
            }

            M.toast({
              html: 'Verifieringen misslyckades',
              classes: 'red darken-1 rounded'
            });
          }
        });
      },
      resend2faCode: function resend2faCode() {
        var that = this;

        if (!this.get('maySendCode')) {
          return;
        }

        _jquery.default.ajax(_environment.default.APP.apiHost + '/api/auth/2fa-resend', {
          type: 'POST',
          contentType: "application/json",
          data: JSON.stringify({
            email: that.userEmail
          }),
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: function success() {
            var $resendCode = (0, _jquery.default)('a#resend-code');
            $resendCode.css('opacity', '0.5');
            $resendCode.css('cursor', 'not-allowed');
            that.set('maySendCode', false); // Hinder user from spamming the "resend code" link by deactivating it for 30 seconds

            setTimeout(function () {
              that.set('maySendCode', true);
              $resendCode.css('opacity', '1');
              $resendCode.css('cursor', 'pointer');
            }, 30 * 1000);
            M.toast({
              html: 'En ny kod har skickats till dig',
              classes: 'green darken-1 rounded'
            });
          },
          error: function error() {
            M.toast({
              html: 'Det gick ej att skicka en ny kod till dig',
              classes: 'red darken-1 rounded'
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});