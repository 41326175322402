define("frontend/templates/components/reset-request-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NtKRC2ez",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",true],[10,\"id\",\"login-form\"],[10,\"class\",\"\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[8],[0,\"\\n    Återställning av lösenord\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isInvalidRequest\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[0,\"Länken som du har använt är inte giltig längre. Vänligen begär en ny återställningslänk nedan.\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"for\",\"email\"],[8],[0,\"Ange din e-postadress för lösenordsåterställning\"],[9],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"value\",\"id\"],[[24,[\"email\"]],[24,[\"email\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"button\",false],[12,\"class\",\"btn waves-effect waves-light\"],[12,\"type\",\"submit\"],[3,\"action\",[[23,0,[]],\"requestResetAction\"]],[8],[0,\"Begär återställning\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/reset-request-form.hbs"
    }
  });

  _exports.default = _default;
});