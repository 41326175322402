define("frontend/templates/auth/2fa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+lavgQsp",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"login-form\",null,[[\"isUserLoggedIn\",\"show2fa\",\"userEmail\"],[[24,[\"application\",\"isUserLoggedIn\"]],true,[24,[\"email\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/auth/2fa.hbs"
    }
  });

  _exports.default = _default;
});