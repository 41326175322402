define("frontend/templates/components/info-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fja4igCb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"isOpen\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",\"info-box-underlay\"],[3,\"on\",[\"click\",[24,[\"closeAction\"]]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"id\",[22,\"htmlId\"]],[10,\"class\",\"info-box\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n    \"],[14,1],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"info-box-close-button-container\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"grid-filler\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"grid-filler\"],[8],[9],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn waves-effect greem\"],[3,\"action\",[[23,0,[]],[24,[\"closeAction\"]]]],[8],[0,\"Stäng\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/info-box.hbs"
    }
  });

  _exports.default = _default;
});