define("frontend/components/file-upload-form", ["exports", "ember-data", "frontend/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function getValidExtensionsAsString(extensions) {
    if (Array.isArray(extensions)) {
      return extensions.map(function (extension) {
        return '.' + extension;
      }).toString();
    } else if (_typeof(extensions) === 'object') {
      return Object.keys(extensions).map(function (key) {
        return '.' + extensions[key];
      }).toString();
    }
  }

  var _default = Ember.Component.extend({
    fileTypes: Ember.computed('user.owner_id', function () {
      var _this = this;

      if (!this.get('user.owner_id')) {
        return;
      }

      var promise = new Promise(function (resolve) {
        jQuery.ajax(_environment.default.APP.apiHost + '/api/upload/definitions/?version=2&as_array=1&owner_id=' + _this.get('user.owner_id'), {
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true
        }).then(function (response) {
          var result = [];

          for (var key in response) {
            if (response.hasOwnProperty(key) && response[key] && response[key].type !== 'image') {
              var extensions = getValidExtensionsAsString(response[key].valid_extensions);
              result.push({
                value: key,
                label: response[key].name,
                extensions: extensions
              });
            }
          }

          _this.set('acceptedFileTypes', result[0].extensions);

          resolve(result);
        });
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    actions: {
      fileTypeChange: function fileTypeChange(value) {
        var selectedFileType = this.fileTypes.filter(function (file) {
          return file.value == value;
        });
        if (!selectedFileType[0]) return;
        this.set('acceptedFileTypes', selectedFileType[0].extensions);
      },
      upload: function upload() {
        var _this2 = this;

        var form = document.getElementById('file-upload');
        var errors = {};
        var maxSize = this.config.maxUploadSize;
        var file = document.getElementById('file');

        if (!file.value) {
          errors.files = ['Du måste välja en fil som ska laddas upp'];
        }

        var size = 0;

        for (var filesIndex = 0; filesIndex < file.files.length; filesIndex++) {
          size += file.files.item(filesIndex).size;
        }

        var isMaxSizeExceeded = size > maxSize;

        if (isMaxSizeExceeded) {
          errors.files = ['De valda filerna överskrider högsta tillåtna storleken!'];
        }

        this.set('errors', errors);

        if (!form.checkValidity() || isMaxSizeExceeded) {
          M.toast({
            html: 'Det gick inte att ladda upp filen',
            classes: 'red darken-1 rounded'
          });
          return;
        }

        this.set('isLoading', true);
        var formData = new FormData(form);
        var xhr = new XMLHttpRequest();
        var component = this;
        xhr.addEventListener('error', function () {
          M.toast({
            html: 'Det gick inte att ladda upp filen',
            classes: 'red darken-1 rounded'
          });

          _this2.set('isLoading', false);
        });
        xhr.addEventListener('load', function () {
          try {
            var data = JSON.parse(this.responseText);

            if (data.errors && Object.keys(data.errors).length > 0) {
              component.set('errors', data.errors);
              M.toast({
                html: 'Det gick inte att ladda upp filen',
                classes: 'red darken-1 rounded'
              });
            } else if (data.success) {
              M.toast({
                html: 'Filen har mottagits!',
                classes: 'blue darken-1 rounded'
              });
              form.reset();
            } else {
              component.set('errors', {
                _general: ['Ett fel påträffades när filerna skulle laddas upp']
              });
              M.toast({
                html: 'Det gick inte att ladda upp filen',
                classes: 'red darken-1 rounded'
              });
            }
          } catch (e) {
            component.set('errors', {
              _general: ['Ett fel påträffades när filerna skulle laddas upp']
            });
            M.toast({
              html: 'Det gick inte att ladda upp filen',
              classes: 'red darken-1 rounded'
            });
          }

          component.set('isLoading', false);
        });
        xhr.withCredentials = true;
        xhr.open('POST', _environment.default.APP.apiHost + '/api/upload/', true);
        xhr.send(formData);
      }
    }
  });

  _exports.default = _default;
});