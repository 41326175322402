define("frontend/helpers/is-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function isEqual(params) {
    if (params.length !== 2) return false;
    return params[0] == params[1];
  });

  _exports.default = _default;
});