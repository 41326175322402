define("frontend/components/info-box", ["exports", "ember-keyboard-shortcuts"], function (_exports, _emberKeyboardShortcuts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    htmlId: '',
    closeAction: '',
    title: '',
    isOpen: false,
    isOpenChanged: Ember.observer('isOpen', function () {
      var isOpen = this.get('isOpen');

      if (isOpen) {
        this.send('unbindRouteEscapeKey');
        return;
      }

      this.send('rebindRouteEscapeKey');
    }),
    keyboardShortcuts: {
      esc: {
        action: 'closeOnKeyboardPress'
      }
    },
    didInsertElement: function didInsertElement() {
      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
    },
    willDestroyElement: function willDestroyElement() {
      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
    },
    actions: {
      closeOnKeyboardPress: function closeOnKeyboardPress() {
        if (typeof this.closeAction !== 'function') {
          return;
        }

        this.closeAction();
      },
      unbindRouteEscapeKey: function unbindRouteEscapeKey() {
        if (!this.unbindRouteEscapeKey) {
          return;
        }

        this.unbindRouteEscapeKey();
      },
      rebindRouteEscapeKey: function rebindRouteEscapeKey() {
        if (!this.rebindRouteEscapeKey) {
          return;
        }

        this.rebindRouteEscapeKey();
      }
    }
  });

  _exports.default = _default;
});