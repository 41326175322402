define("frontend/components/document-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    className: Ember.computed('index', 'documentIndex', 'statusColor', function () {
      var classNames = [];

      if (this.get('index') === this.get('documentIndex')) {
        classNames.push('current-item');
      }

      classNames.push(this.get('statusColor'));
      return classNames.join(' ');
    })
  });

  _exports.default = _default;
});