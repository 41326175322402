define("frontend/components/document-list", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    _scrollHandler: function _scrollHandler(ev) {
      var $this = $(ev.target);

      if ($this.find('.tableWrapper')) {
        if ($this.scrollTop() + $this.innerHeight() >= $this[0].scrollHeight - 100) {
          if (!this.get('isLoading')) {
            this.loadMoreDocuments();
          }
        }
      }
    },
    didInsertElement: function didInsertElement() {
      (0, _jquery.default)('#search-input').focus();
      this._boundScrollHandler = this._scrollHandler.bind(this);
      (0, _jquery.default)('.content-wrapper').scroll(this._boundScrollHandler);
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)('.content-wrapper').unbind('scroll', this._boundScrollHandler);
    },
    avrstats: [{
      name: 'Godkänns',
      value: '1'
    }, {
      name: 'Ej avräknad',
      value: '2'
    }, {
      name: 'Felavräknad',
      value: '3'
    }, {
      name: 'Övrigt',
      value: '4'
    }, {
      name: 'Avräknas',
      value: '5'
    }, {
      name: 'Faktureras',
      value: '6'
    }, {
      name: 'Avräknas ej',
      value: '7'
    }, {
      name: 'Avräknad aut',
      value: '8'
    }, {
      name: 'Avräknad man',
      value: '9'
    }],
    filteredOwnerAttributes: Ember.computed.filterBy('ownerAttributes', 'attr_list_display', true),
    actions: {
      sortByAction: function sortByAction(property) {
        if (this.get('sortOrder') === property) {
          this.toggleProperty('sortAscending');
        }

        this.set('sortOrder', property);
      }
    }
  });

  _exports.default = _default;
});