define("frontend/utils/is-authenticated", ["exports", "jquery", "frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isAuthenticated;

  function isAuthenticated() {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      _jquery.default.ajax(_environment.default.APP.apiHost + '/api/auth/', {
        type: 'GET',
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true
      }).then(function (res) {
        if (res.authenticated) {
          resolve();
        } else {
          reject();
        }
      }, reject);
    });
  }
});