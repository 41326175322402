define("frontend/controllers/documents", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    bugReporting: Ember.inject.service(),
    documents: [],
    isLoading: false,
    index: 0,
    zoomValue: 0,
    rotatedDegrees: 0,
    rotate: 3,
    showDocBtns: true,
    documentQuery: '',
    documentAttributesQuery: '',
    documentFilter: '',
    filterOwnerId: '',
    activatedFilter: '',
    realDocLength: '...',
    isLoadingMoreStopped: false,
    newEmail: '',
    latestContacts: [],
    latestContact: Ember.computed('latestContacts', function () {
      var latestContact = this.get('latestContacts.firstObject');

      if (latestContact === 'Ny mottagare') {
        this.set('latestContacts', [this.get('application.user.email'), 'Ny mottagare']);
      }

      return this.get('latestContacts.firstObject');
    }),
    newContactSelected: Ember.computed.equal('email', 'Ny mottagare'),
    realDocLengthText: Ember.computed('realDocLength', function () {
      if (this.realDocLength === '...') {
        return this.realDocLength;
      }

      return this.realDocLength + ' totalt';
    }),
    currentDocument: Ember.computed('documents.@each.id', 'index', function () {
      return this.get('documents').objectAt(this.get('index'));
    }),
    loadMoreDocuments: function loadMoreDocuments() {
      var _this = this;

      var documents = this.get('documents');

      if (this.isLoadingMoreStopped) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          _this.storeBugReportingData('load more documents rejected');

          reject();
        });
      } // _state is undefined when promise is pending...


      if (this.get('loadMorePromise') && !this.get('loadMorePromise._state')) {
        // Return the same promise again to avoid multiple searches
        return this.get('loadMorePromise');
      }

      var user = this.get('application.user');
      var query = {
        owner_id: user.get('owner_id'),
        limit: 50,
        offset: documents.get('length')
      };

      if (this.get('documentQuery')) {
        query['query'] = this.documentQuery;
      }

      if (this.get('documentAttributesQuery')) {
        query['attributes'] = this.documentAttributesQuery;
      }

      if (this.get('documentFilter')) {
        query.filter = this.documentFilter.id;
      }

      this.set('isLoading', true);
      this.storeBugReportingData('load more documents');
      var promise = new Ember.RSVP.Promise(function (resolve) {
        _this.store.query('document', query).then(function (newDocuments) {
          if (newDocuments.length === 0) {
            _this.set('isLoadingMoreStopped', true);

            _this.storeBugReportingData('load more documents reached the end');
          }

          newDocuments.forEach(function (document) {
            documents.pushObject(document);
          });

          _this.set('isLoading', false);

          resolve(documents);

          _this.storeBugReportingData('load more document successful');
        });
      });
      this.set('loadMorePromise', promise);
      return promise;
    },
    removeDocument: function removeDocument(document) {
      this.storeBugReportingData('remove document');
      this.get('documents').removeObject(document);

      if (typeof this.realDocLength === 'number') {
        this.decrementProperty('realDocLength');
      }
    },
    restoreDocument: function restoreDocument(document) {
      this.storeBugReportingData('restore document');
      var documents = this.get('documents');

      if (!documents.includes(document)) {
        documents.addObject(document);

        if (typeof this.realDocLength === 'number') {
          this.incrementProperty('realDocLength');
        }
      }
    },
    storeBugReportingData: function storeBugReportingData(action) {
      if (action) {
        this.get('bugReporting.actions').push(action);
      }

      this.set('bugReporting.data.documents', {
        documents: this.get('documents.length'),
        isLoading: this.get('isLoading'),
        index: this.get('index'),
        zoomValue: this.get('zoomValue'),
        rotatedDegrees: this.get('rotatedDegrees'),
        rotate: this.get('rotate'),
        showDocBtns: this.get('showDocBtns'),
        documentQuery: this.get('documentQuery'),
        documentAttributesQuery: this.get('documentAttributesQuery'),
        documentFilter: this.get('documentFilter'),
        filterOwnerId: this.get('filterOwnerId'),
        activatedFilter: this.get('activatedFilter'),
        realDocLength: this.get('realDocLength'),
        isLoadingMoreStopped: this.get('isLoadingMoreStopped')
      });
      this.incrementProperty('bugReporting.version');
    },
    actions: {
      updateDownloadLink: function updateDownloadLink(query) {
        var value;

        if (query && this.documents.length) {
          var queryString = ['download=1'];

          for (var key in query) {
            if (query.hasOwnProperty(key)) {
              var type = _typeof(query[key]);

              if (type === 'string') {
                queryString.push(key + '=' + query[key]);
              } else if (type === 'object') {
                for (var _i = 0, _Object$entries = Object.entries(query[key]); _i < _Object$entries.length; _i++) {
                  var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
                      k = _Object$entries$_i[0],
                      v = _Object$entries$_i[1];

                  queryString.push("".concat(key, "[").concat(k, "]=").concat(v));
                }
              } else {
                throw new Error('Unable to generate download link for type ' + type);
              }
            }
          }

          value = _environment.default.APP.apiHost + '/api/documents/?' + queryString.join('&');
        }

        this.set('application.downloadLink', value);
      },
      updateDocumentCount: function updateDocumentCount(query) {
        var _this2 = this;

        // Abort any previous query
        if (this.updateDocumentCountRequest) {
          this.updateDocumentCountRequest.abort();
        } // Reset to unknown state


        this.set('realDocLength', '...'); // If a query is given we're about to update the current count

        if (query) {
          // If number of documents is below limit, just use that
          if (this.documents.length < 50) {
            this.set('realDocLength', this.documents.length);
          } else {
            // Otherwise request actual count asynchronously from the server
            query.length = 'true';
            this.updateDocumentCountRequest = jQuery.ajax(_environment.default.APP.apiHost + '/api/documents/', {
              type: 'POST',
              data: query,
              xhrFields: {
                withCredentials: true
              },
              crossDomain: true
            });
            this.updateDocumentCountRequest.then(function (resp) {
              _this2.set('realDocLength', resp.documents);

              _this2.updateDocumentCountRequest = undefined;
            });
          }
        }
      },
      lookForSettlementData: function lookForSettlementData(query, quickSearch) {
        var url = new URL(_environment.default.APP.apiHost + '/api/documents');
        url.searchParams.append('owner_id', query.owner_id);
        url.searchParams.append('query', query.query);
        url.searchParams.append('settlementData', '1');
        $.ajax(url.toString(), {
          type: 'GET',
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: function success(response) {
            var settlementData = response.settlementData;
            var fsnr = response.fsnr;

            if (!settlementData) {
              return;
            }

            quickSearch.set('settlementFsnr', fsnr);
            quickSearch.set('settlementData', settlementData);
            quickSearch.set('settlementModalOpen', true);
          }
        });
      }
    }
  });

  _exports.default = _default;
});