define("frontend/components/attribute-value", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['attribute-value'],
    classNameBindings: ['ownerAttribute.attr_show_wide'],
    isCheckbox: Ember.computed.equal('ownerAttribute.attr.attr_validation', 'set(,J)'),
    isSelect: Ember.computed('ownerAttribute.attr.attr_validation', 'ownerAttribute.owner.hasFewTransporterNumbers', 'ownerAttribute.attr.isTransporterNumber', 'ownerAttribute.attr.isAssignment', function () {
      if (this.get('ownerAttribute.attr.isTransporterNumber') && this.get('ownerAttribute.owner.hasFewTransporterNumbers')) {
        return true;
      }

      if (this.get('ownerAttribute.attr.isAssignment')) return true;
      var validation = this.get('ownerAttribute.attr.attr_validation');

      if (!validation) {
        return false;
      }

      return validation.match(/set\(/) && validation !== 'set(,J)';
    }),
    errors: Ember.computed('attributeValue.errors.length', 'attributeValue.invalid', 'attributeValue.hasDirtyAttributes', function () {
      var _this = this;

      var promise = new Promise(function (resolve) {
        var errors = _this.get('attributeValue.errors').toArray();

        var isInvalidByBackend = _this.get('attributeValue.invalid');

        var hasDirtyAttributes = _this.get('attributeValue.hasDirtyAttributes'); // Add a custom message for attributes invalid by backend, as long as the property has not changed


        if (isInvalidByBackend && !hasDirtyAttributes) {
          _this.get('attributeValue.attr').then(function (attribute) {
            var message = attribute.get('attr_error');

            if (!message) {
              message = 'Detta fält kräver validering';
            }

            errors.push({
              message: message
            });
            resolve(errors);
          });
        } else {
          resolve(errors);
        }
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    setFocus: Ember.on('didInsertElement', function () {
      var attributeValue = this.get('attributeValue');
      var focusedAttribute = this.get('focusedAttribute'); // Only focus invalid attributes or previously focused

      if (!focusedAttribute && attributeValue.get('valid')) {
        return;
      }

      var attributeValues = this.get('attributeValues');
      var invalidAttributes = attributeValues.filterBy('invalid');

      function focusElement() {
        var _this2 = this;

        setTimeout(function () {
          var $el = _this2.$().find('input, select');

          if ($el.length) $el.focus();
        }, 250);
      } // This is the first (or only) invalid attribute


      var attributeId = attributeValue.get('attr.id');

      if (invalidAttributes.get('firstObject.attr.id') === attributeId) {
        focusElement.apply(this);
      } else if (invalidAttributes.get('length') === 0 && focusedAttribute === attributeId) {
        focusElement.apply(this);
      }
    }),
    setupFocusListener: Ember.on('didInsertElement', function () {
      var _this3 = this;

      this._boundFocusListener = function () {
        _this3.set('focusedAttribute', _this3.get('attributeValue.attr.id'));
      };

      this._boundBlurListener = function () {
        _this3.set('focusedAttribute');
      };

      this.$().find('input, select').on('focus', this._boundFocusListener);
      this.$().find('input, select').on('blur', this._boundBlurListener);
    }),
    destroyFocusListener: Ember.on('willDestroyElement', function () {
      this.$().find('input, select').off('focus', this._boundFocusListener);
      this.$().find('input, select').off('blur', this._boundBlurListener);
    }),
    checkedValue: Ember.computed('attributeValue.attr.attr_validation', function () {
      var validation = this.get('attributeValue.attr.attr_validation');

      if (!validation) {
        return;
      }

      validation = validation.replace(/set\(/, '');
      validation = validation.replace(/\)/, '');
      var parts = validation.split(',');
      return parts[1]; // First item is for "false"
    }),
    uncheckedValue: Ember.computed('attributeValue.attr.attr_validation', function () {
      var validation = this.get('attributeValue.attr.attr_validation');

      if (!validation) {
        return;
      }

      validation = validation.replace(/set\(/, '');
      validation = validation.replace(/\)/, '');
      var parts = validation.split(',');
      return parts[0]; // First item is for "false"
    }),
    isChecked: Ember.computed('attributeValue.value', 'checkedValue', 'uncheckedValue', {
      'set': function set(key, value) {
        var attributeValue = value ? this.get('checkedValue') : this.get('uncheckedValue');
        this.set('attributeValue.value', attributeValue);
        return value;
      },
      'get': function get() {
        return this.get('attributeValue.value') === this.get('checkedValue');
      }
    }),
    ownerAttribute: Ember.computed('attributeValue.attr.id', 'ownerAttributes.@each.attr.id', function () {
      var ownerAttributes = this.get('ownerAttributes');

      if (ownerAttributes) {
        return ownerAttributes.findBy('attr.id', this.get('attributeValue.attr.id'));
      }
    }),
    isWideLayout: Ember.computed.alias('ownerAttribute.attr_show_wide'),
    isEditable: Ember.computed.alias('ownerAttribute.attr_web_edit'),
    isDisabled: Ember.computed('isEditable', 'parentView.hasWritePermission', function () {
      if (!this.isEditable) return true;
      return !this.get('parentView.hasWritePermission');
    }),
    showWide: true,
    showNarrow: true,
    shouldShow: Ember.computed('isWideLayout', 'showWide', 'showNarrow', function () {
      return this.isWideLayout && this.showWide || !this.isWideLayout && this.showNarrow;
    }),
    attributeInfo: Ember.computed('attributeValue.attr.attr_info', function () {
      var attributeInfo = this.get('attributeValue.attr.attr_info');

      if (!attributeInfo) {
        return '';
      }

      return attributeInfo.replace(/\r?\n/g, "<br>");
    }),
    hasAttributeInfo: Ember.computed.notEmpty('attributeInfo'),
    shouldShowAttributeInfo: false,
    actions: {
      showAttributeInfo: function showAttributeInfo() {
        this.set('shouldShowAttributeInfo', true);
      },
      closeAttributeInfo: function closeAttributeInfo() {
        this.set('shouldShowAttributeInfo', false);
      }
    }
  });

  _exports.default = _default;
});