define("frontend/models/report", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    report_name: _emberData.default.attr('string'),
    report_description: _emberData.default.attr('string'),
    report_default_config: _emberData.default.attr('json'),
    report_help: _emberData.default.attr('string')
  });

  _exports.default = _default;
});