define("frontend/routes/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    tryUnlockDocument: function tryUnlockDocument(model) {
      if (!model) {
        return;
      }

      if (model.constructor.toString() !== 'model:document') {
        return;
      }

      var application = this.controllerFor('application');
      model.get('lock').then(function (lock) {
        if (lock && !lock.get('isDeleted')) {
          application.get('user').then(function (user) {
            // Only remove your own locks
            if (lock.get('user.id') === user.get('id') && !lock.get('isDeleted')) {
              lock.deleteRecord();
              lock.save().then(function () {
                model.set('lock');
              });
            }
          });
        }
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        var model = this.controllerFor('documents.document').get('model');

        if (transition.isActive && model && model.get('isDirty')) {
          var message = 'Du har ändringar som inte är sparade. Är du säker på att du vill fortsätta? Dina ändringar kommer att återställas';
          var reset = window.confirm(message);

          if (reset) {
            if (transition.from.name === 'documents.document') {
              this.tryUnlockDocument(model);
            }

            return model.rollback();
          } else {
            transition.abort();
          }
        }

        if (transition.from.name === 'documents.document') {
          this.tryUnlockDocument(model);
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});