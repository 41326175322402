define("frontend/templates/components/settlement-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4jOajKVa",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showSettlementLink\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"settlementLinkEnabled\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"showExternalLink\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\",true],[11,\"href\",[29,[\"https://www.dhlt.se/mina-sidor/sok-sandning/?fsnr=\",[22,\"fsnr\"]]]],[10,\"class\",\"btn waves-effect waves-light tooltipped\"],[10,\"data-tooltip\",\"Visa avräkningsinformation på sändningen\"],[10,\"target\",\"_blank\"],[8],[0,\"\\n        Avräkning\"],[7,\"i\",true],[10,\"class\",\"material-icons right\"],[8],[0,\"attach_money\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"button\",false],[12,\"class\",\"btn waves-effect waves-light tooltipped\"],[12,\"data-tooltip\",\"Visa avräkningsinformation på sändningen\"],[3,\"action\",[[23,0,[]],\"openSettlementModal\"]],[8],[0,\"Avräkning\"],[7,\"i\",true],[10,\"class\",\"material-icons right\"],[8],[0,\"attach_money\"],[9],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"settlementModalOpen\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"settlement-modal\",null,[[\"fsnr\",\"sndnr\",\"document\",\"owner\",\"open\"],[[24,[\"fsnr\"]],[24,[\"sndnr\"]],[24,[\"document\"]],[24,[\"owner\"]],[24,[\"settlementModalOpen\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"button\",true],[10,\"disabled\",\"\"],[10,\"class\",\"btn waves-effect waves-light\"],[8],[0,\"Avräkning\"],[7,\"i\",true],[10,\"class\",\"material-icons right\"],[8],[0,\"attach_money\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/settlement-link.hbs"
    }
  });

  _exports.default = _default;
});