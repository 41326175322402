define("frontend/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5xkl1jlB",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"application\",null,[[\"isDebugging\",\"isUserLoggedIn\",\"user\",\"config\",\"restart\",\"ownerAttributes\",\"downloadLink\",\"definitionTypes\"],[[24,[\"isDebugging\"]],[24,[\"isUserLoggedIn\"]],[24,[\"user\"]],[24,[\"config\"]],[28,\"route-action\",[\"toRoute\",\"index\"],null],[24,[\"ownerAttributes\"]],[24,[\"downloadLink\"]],[24,[\"definitionTypes\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/application.hbs"
    }
  });

  _exports.default = _default;
});