define("frontend/models/document-lock", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    created: _emberData.default.attr('moment'),
    document: _emberData.default.belongsTo('document'),
    user: _emberData.default.belongsTo('user'),
    instance: _emberData.default.attr('string'),
    count: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    removeYourself: function removeYourself() {
      this.deleteRecord();
      this.save();
    }
  });

  _exports.default = _default;
});