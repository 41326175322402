define("frontend/controllers/documents/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    documents: Ember.inject.controller(),
    application: Ember.inject.controller(),
    index: Ember.computed.alias('documents.index'),
    isLoading: Ember.computed.alias('documents.isLoading'),
    actions: {
      loadMoreDocuments: function loadMoreDocuments() {
        this.get('documents').loadMoreDocuments();
      }
    }
  });

  _exports.default = _default;
});