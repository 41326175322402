define("frontend/components/settlement-basis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rows: [{
      'Fornamn': 'Rickard',
      'Efternamn': 'Andersson',
      'Alder': 34,
      'Titel': 'Webbutvecklare'
    }, {
      'Fornamn': 'Alexander',
      'Efternamn': 'Runemark',
      'Alder': 20,
      'Titel': 'Webbutvecklare'
    }],
    showDropdown: false,
    actions: {
      test: function test() {
        if (this.get('showDropdown')) {
          this.set('showDropdown', false);
        } else {
          this.set('showDropdown', true);
        }

        return this.get('row');
      }
    }
  });

  _exports.default = _default;
});