define("frontend/templates/documents/document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0eO0Y/Ax",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"document\",null,[[\"document\",\"isLockedByOther\",\"zoomValue\",\"rotatedDegrees\",\"pageNumber\",\"user\",\"ownerAttributes\",\"isLoading\",\"isUserLoggedIn\",\"documentFilter\",\"nextDocumentAction\",\"saveToNextAction\",\"saveToListAction\",\"saveCopyAction\",\"deleteDocumentAction\",\"resetPageNumberAction\",\"restoreDocumentAction\"],[[24,[\"model\"]],[24,[\"isLockedByOther\"]],[24,[\"zoomValue\"]],[24,[\"rotatedDegrees\"]],[24,[\"pageNumber\"]],[24,[\"application\",\"user\"]],[24,[\"application\",\"ownerAttributes\"]],[24,[\"isLoading\"]],[24,[\"application\",\"isUserLoggedIn\"]],[24,[\"documents\",\"documentFilter\"]],[28,\"route-action\",[\"nextDocumentAction\"],null],[28,\"action\",[[23,0,[]],\"saveToNextAction\"],null],[28,\"action\",[[23,0,[]],\"saveToListAction\"],null],[28,\"action\",[[23,0,[]],\"saveCopyAction\"],null],[28,\"action\",[[23,0,[]],\"deleteDocumentAction\"],null],[28,\"action\",[[23,0,[]],\"resetPageNumberAction\"],null],[28,\"action\",[[23,0,[]],\"restoreDocumentAction\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/documents/document.hbs"
    }
  });

  _exports.default = _default;
});