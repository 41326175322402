define("frontend/controllers/application", ["exports", "frontend/utils/is-authenticated", "frontend/config/environment", "ember-data"], function (_exports, _isAuthenticated, _environment, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    permissions: Ember.inject.service(),
    isUserLoggedIn: false,
    isDebugging: false,
    instance: Ember.computed(function () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
      });
    }),
    checkIfAuth: Ember.on('init', function () {
      var _this = this;

      var promise = new Promise(function (resolve) {
        (0, _isAuthenticated.default)().then(function () {
          _this.set('isUserLoggedIn', true);

          resolve();
        });
      });
      this.set('didLoginPromise', promise);
    }),
    loadConfiguration: Ember.on('init', function () {
      var _this2 = this;

      jQuery.ajax(_environment.default.APP.apiHost + '/api/config/', {
        type: 'GET',
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true
      }).then(function (response) {
        _this2.set('config', response);
      });
    }),
    setUserContext: function setUserContext(user) {
      if (typeof Raven !== 'undefined') {
        Raven.setUserContext({
          id: user.get('id'),
          email: user.get('email')
        });
      }
    },
    user: Ember.computed('isUserLoggedIn', function () {
      var _this3 = this;

      if (!this.isUserLoggedIn) {
        return new Promise(function () {
          return _this3.get('didLoginPromise').then(function () {
            var that = _this3;

            var user = _this3.store.queryRecord('user', {
              me: true
            });

            user.then(function (u) {
              that.setUserContext(u);
              var ownerId = u.get('owner_id'); // No need to query the backend for permissions if we already know them

              if (that.permissions.get('owner_id') === ownerId && that.permissions.get('permission') !== null) {
                return;
              }

              $.ajax(_environment.default.APP.apiHost + '/api/users/get-permission?owner_id=' + ownerId, {
                type: 'GET',
                xhrFields: {
                  withCredentials: true
                },
                crossDomain: true,
                success: function success(response) {
                  that.permissions.set('permission', response.permission);
                  that.permissions.set('owner_id', ownerId);
                },
                error: function error() {
                  that.permissions.set('permission', null);
                  that.permissions.set('owner_id', null);
                }
              });
            });
            return user;
          });
        });
      }

      var user = this.store.queryRecord('user', {
        me: true
      });
      user.then(this.setUserContext);
      return user;
    }),
    definitionTypes: Ember.computed('user.owner_id', function () {
      var _this4 = this;

      if (!this.get('user.owner_id')) {
        return;
      }

      var promise = new Promise(function (resolve) {
        jQuery.ajax(_environment.default.APP.apiHost + '/api/upload/definitions/?version=2&owner_id=' + _this4.get('user.owner_id'), {
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true
        }).then(function (response) {
          var result = [];

          for (var item in response) {
            if (response.hasOwnProperty(item) && response[item].type === 'image') {
              result.push({
                value: item,
                label: response[item].name
              });
            }
          }

          resolve(result);
        });
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    allOwnerAttributes: Ember.computed('user.owner_id', function () {
      if (!this.get('user.owner_id')) {
        return [];
      }

      return this.store.query('owner-attribute', {
        owner_id: this.get('user.owner_id')
      });
    }),
    visibleOwnerAttributes: Ember.computed.filterBy('allOwnerAttributes', 'attr_web_display'),
    _unsortedSearchableOwnerAttributes: Ember.computed.filterBy('allOwnerAttributes', 'attr_web_search'),
    // Use this value for common usages
    ownerAttributes: Ember.computed.sort('visibleOwnerAttributes', 'attributeSort'),
    searchableOwnerAttributes: Ember.computed.sort('_unsortedSearchableOwnerAttributes', 'attributeSort'),
    attributeSort: ['attr_web_sort_order', 'attr.attr_display_name']
  });

  _exports.default = _default;
});